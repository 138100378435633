import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { updateBasket, updateFavorites } from "redux/slices/basketStore";
import { useGuestLayout } from "pages/client/menu-v2/GuestLayoutContext";
import MenuCard, { ENUMS } from "pages/client/menu-v2/MenuCard/MenuCard";
import useLanguage from "utils/hooks/useLanguage";
import { STORE_NAMES } from "utils/constants/redux";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import {
  findGuest,
  getBasketModificationsWithDefaultValues,
} from "utils/helpers";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";

import styles from "./Category.module.scss";

const Category = ({ category }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { displayDataByLanguage } = useLanguage();
  const dispatch = useDispatch();
  const { businessId } = useParams();
  const { pathname } = useLocation();
  const { showTabBar } = useGuestLayout();
  const { shouldDisplayMenuItemImage } = useMenuHelper();

  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);

  const handleAddToBasket = (menuItem) => {
    const filteredModifications = getBasketModificationsWithDefaultValues(
      menuItem.modifications
    ).filter((modification) => modification.options.length > 0);

    dispatch(
      updateBasket({
        menuItem: {
          id: menuItem.id,
          modifications: filteredModifications.map((modification) => ({
            id: modification.id,
            options: modification.options.map((option) => ({
              id: option.id,
              count: option.count ?? 1,
            })),
          })),
        },
        count: 1,
        userId: guestId,
      })
    );
    showTabBar();
  };
  const userId = useSelector((state) => state[STORE_NAMES.guest].id);
  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const guest = findGuest(userId, basketOrders);
  const favoriteItems = guest?.favoriteItems || [];

  const handleGoSeeAllPage = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.menu}${ROUTE_NAME.category}/${category?.id}`
    );
  };

  const handleFavItem = (menuItem) => {
    dispatch(
      updateFavorites({
        userId: guestId,
        menuItemID: menuItem.id,
      })
    );
  };

  const handleOnClick = (item) => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItem}/${item.id}?${QUERY_PARAMS.from}=${pathname}`
    );
  };

  return (
    <>
      <div className={styles.Category}>
        <div className={styles.Title}>
          <h2 className={cx(styles.Name, "SemiBold")}>
            {displayDataByLanguage(category.name)}
          </h2>
          {category.menuItems.length > 1 && (
            <h6
              className={cx(styles.All, "SemiBold")}
              onClick={handleGoSeeAllPage}
            >
              {t("buttons.seeAll")}
            </h6>
          )}
        </div>

        <div
          className={cx(styles.Cards, {
            [styles.Vertical]: !shouldDisplayMenuItemImage,
            [styles.Horizontal]: shouldDisplayMenuItemImage,
          })}
        >
          {category?.menuItems?.map((menuItem) => {
            return (
              <MenuCard
                key={menuItem.id}
                menuItem={menuItem}
                onAddToBasket={handleAddToBasket}
                type={
                  shouldDisplayMenuItemImage
                    ? ENUMS.types.MEDIUM
                    : ENUMS.types.WITHOUT_IMAGE
                }
                isFavorite={favoriteItems.includes(menuItem.id)}
                onFavorite={() => handleFavItem(menuItem)}
                favoriteItems={favoriteItems}
                onClick={() => handleOnClick(menuItem)}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
Category.propTypes = {
  category: PropTypes.object,
};

export default Category;
