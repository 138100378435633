import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";

import "./EmptyState.scss";

export const ENUMS = {
  name: "EmptyState",
  types: {
    //   SIZE_S: "S",
    //   SIZE_M: "M",
    //   SIZE_L: "L",
  },
};
const EmptyState = ({
  title,
  description,
  icon,
  isAdmin,
  className,
  closeButtonHandler,
  descriptionClassName,
}) => {


  return (
    <div
      className={cx("EmptyStateContainer", className, {
        isAdmin,
        // sizeS: type === ENUMS.types.SIZE_S,
        // sizeM: type === ENUMS.types.SIZE_M,
      })}

    >
      {closeButtonHandler && (
        <CloseButton
          onClick={closeButtonHandler}
          type={ENUMS_CLOSE_BUTTON.types.TYPE_E}
          className={"AdminEmptyStateCloseBtn"}
        />
      )}
      <img src={icon} alt="empty" />
      {title && <h2 className="Medium EmptyStateTitle">{title}</h2>}
      <div className={`EmptyStateDescription ${descriptionClassName}`}>
        {description}
      </div>
    </div>
  );
};

EmptyState.propTypes = {
  /**
   * Title to be displayed in the empty state
   */
  title: PropTypes.string,

  /**
   * Description to be displayed in the empty state
   */
  description: PropTypes.string.isRequired,

  /**
   * Icon to be displayed in the empty state
   */
  icon: PropTypes.string.isRequired,

  heroHeight: PropTypes.number,

  /**
   * Determines if the empty state is for an admin
   */
  isAdmin: PropTypes.bool.isRequired,

  /**
   * Additional class names to be added to the empty state
   * */
  className: PropTypes.string,

  closeButtonHandler: PropTypes.func,

  descriptionClassName: PropTypes.string,
};

export default EmptyState;
