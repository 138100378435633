import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import isEqual from "lodash/isEqual";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ErrorMessage } from "@hookform/error-message";

import IconButton from "components/buttons/icon-button/IconButton";
import Spinner from "components/elements/spinner/Spinner";
import AddPhotoButton from "components/admin/buttons/add-photo-button/AddPhotoButton";
import Photo from "components/admin/cards/photo/Photo";
import AddNewButton, {
  ENUMS as ENUMS_ADD_NEW_BUTTON,
} from "components/admin/buttons/add-new-button/AddNewButton";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import Modal from "components/modal/Modal";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import {
  getActiveLanguageValue,
  getBasketModificationsWithDefaultValues,
  getInitialSchedule,
  getTranslationPropertyRequestBody,
} from "utils/helpers";
import ModificationSettings from "pages/admin/admin-pages/admin-menu/admin-menu-controller/modification-settings-modal/ModificationSettingsModal";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { DO_FILE_TYPES, IMAGE_FILE } from "utils/constants/DOSpaces";
import { STORE_NAMES } from "utils/constants/redux";
import Switch, { ENUMS as ENUMS_SWITCH } from "components/forms/switch/Switch";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import Language from "components/admin/elements/language/Language";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import DateAccordion from "components/admin/elements/date-accordion/DateAccordion";
import RadioSelection from "components/admin/forms/radio-selection/RadioSelection";
import ModificationsMenu from "components/elements/modifications-menu/ModificationsMenu";
import {
  createDOBucketName,
  removeImageFromDO,
  uploadImageToDO,
} from "utils/DO-Spaces";
import { filterArchivedCategories } from "utils/helpersMenu";
import cx from "classnames";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";
import { useValidationSchema } from "utils/hooks/useValidationSchema";

import "./AddMenuItemModal.scss";

const ADD_ITEM_METHOD_ENUMS = [{ method: "GENERAL" }, { method: "DETAIL" }];

const ENUMS = {
  unit: "g",
};

const AddMenuItemModal = ({
  item,
  mainElementRef,
  setOpenSlide,
  openSlide,
  onSave,
  onDelete,
  selectedCategory,
  setOutsideClickAction,
  isLoading,
  title,
  showDeniedModal,
}) => {
  const [addItemMethod, setAddItemMethod] = useState(
    ADD_ITEM_METHOD_ENUMS[0].method
  );

  useEffect(() => {
    setAddItemMethod(ADD_ITEM_METHOD_ENUMS[0].method);
  }, [item?.id]);

  const [
    openSlideModification,
    setOpenSlideModification,
    mainElementRefModification,
    ,
    ,
    setOutsideClickActionModification,
  ] = useOutsideClick();

  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();
  const menuCurrency = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency
  );
  const currencyCode = menuCurrency?.code;
  const { t } = useTranslation();

  const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);
  const tags = menu?.tags || [];
  const categories =
    filterArchivedCategories(menu?.categories).map((category) => {
      return { id: category.id, name: category.name };
    }) || [];
  const [coverImageLoading, setCoverImageLoading] = useState(false);
  const [coverImageError, setCoverImageError] = useState(null);
  const { units } = useSelector((state) => state[STORE_NAMES.app]);
  const isEditable = !!item;
  const [selectedModification, setSelectedModification] = useState(null);
  const menuLanguage = menu?.language;

  const initialSchedule = item?.schedule?.from
    ? item?.schedule
    : getInitialSchedule(new Date());

  const initialRateSchedule =
    item?.rate?.schedule?.from ?? getInitialSchedule(new Date());

  const initialData = {
    schedule: initialSchedule,
    name: [{ value: "", languageCode: menuLanguage.code }],
    isPublished: true,
    description: [{ value: "", languageCode: menuLanguage.code }],
    priceSell: "",
    priceCost: "",
    calories: "",
    timeToMake: "",
    amount: "",
    unit: units.find((unit) => unit.name === ENUMS.unit),
    coverImageSrc: "",
    otherImagesSrc: [],
    tags: [],
    category: selectedCategory,
    rate: {
      isFixed: false,
      amount: 0,
      schedule: initialRateSchedule,
      isEnabled: false,
    },
    modifications: [],
  };
  const [activeLanguageCode, setActiveLanguageCode] = useState(null);
  const [isDiscountScheduleAccordionOpen, setIsDiscountScheduleAccordionOpen] =
    useState(false);
  const [isItemScheduleAccordionOpen, setIsItemScheduleAccordionOpen] =
    useState(false);
  const [formData, setFormData] = useState(initialData);
  const [formDataInitial, setFormDataInitial] = useState(initialData);
  const [uploadedImages, setUploadedImages] = useState([]);
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );

  useFormOutsideClickHandler({
    formData,
    formDataInitial,
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
    coverImageLoading,
  });

  const handleOnRemoveRedundantImagesFromAWSWhenCloseModal = () => {
    uploadedImages.map((image) => {
      if (!item?.otherImagesSrc.includes(image)) {
        removeImageFromDO(image);
      }
    });
    setUploadedImages([]);
  };

  useEffect(() => {
    if (!openSlide) {
      handleOnRemoveRedundantImagesFromAWSWhenCloseModal();
    }
    if (item) {
      setFormData({
        schedule: item.schedule.from ? item.schedule : initialSchedule,
        name: item.name,
        isPublished: item.isPublished,
        description: item.description,
        priceSell: item.priceSell,
        priceCost: item.priceCost ?? "",
        calories: item.calories || "",
        timeToMake: item.timeToMake || "",
        amount: item.amount || "",
        unit: item.unit,
        coverImageSrc: item.coverImageSrc,
        otherImagesSrc: item.otherImagesSrc,
        tags: item.tags,
        category: selectedCategory,
        rate: {
          ...item.rate,
          schedule: item.rate.schedule.from
            ? item.rate.schedule
            : initialRateSchedule,
        },
        modifications: item.modifications,
      });
      setFormDataInitial({
        schedule: item.schedule.from ? item.schedule : initialSchedule,
        name: item.name,
        isPublished: item.isPublished,
        description: item.description,
        priceSell: item.priceSell,
        priceCost: item.priceCost ?? "",
        calories: item.calories || "",
        timeToMake: item.timeToMake || "",
        amount: item.amount || "",
        unit: item.unit,
        coverImageSrc: item.coverImageSrc,
        otherImagesSrc: item.otherImagesSrc,
        tags: item.tags,
        category: selectedCategory,
        rate: {
          ...item.rate,
          schedule: item.rate.schedule.from
            ? item.rate.schedule
            : initialRateSchedule,
        },
        modifications: item.modifications,
      });

      setActiveLanguageCode(item.name[0].languageCode);
      setIsDiscountScheduleAccordionOpen(
        item.rate?.schedule?.isActive || false
      );
      setIsItemScheduleAccordionOpen(item.schedule?.isActive || false);
    } else {
      setFormData(initialData);
      setFormDataInitial(initialData);
      setActiveLanguageCode(initialData.name[0].languageCode);
      setIsDiscountScheduleAccordionOpen(initialRateSchedule.isActive);
      setIsItemScheduleAccordionOpen(initialSchedule.isActive);
    }
  }, [item, openSlide]);

  const schemas = useValidationSchema(t);
  const addMenuItemSchema = schemas.addMenuItemSchema(formData);
  const { requiredStringSchemaForMenuItem } = schemas;

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
  } = useForm({
    resolver: zodResolver(addMenuItemSchema),
    criteriaMode: "all",
  });

  useEffect(() => {
    reset({
      formData,
    });
    return () => {
      setCoverImageLoading(false);
      setCoverImageError(null);
    };
  }, [reset, formData]);

  const handleOnInputChange = (e) => {
    const { name, value, type } = e.target;
    const updatedFormData = { ...formData };

    if (type === "number") {
      updatedFormData[name] = value !== "" ? parseFloat(value) : "";
    } else {
      updatedFormData[name] = value;
    }
    setFormData(updatedFormData);
  };

  const handleOnRateChange = (e) => {
    const { value } = e.target;
    const updatedFormData = {
      ...formData,
      rate: {
        ...formData.rate,
        amount: value ? parseFloat(value) : 0,
      },
    };
    setFormData(updatedFormData);
  };
  const handleOnMultiLanguageInputChange = (e, name) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [name]: formData[name].map((property) => {
        if (property.languageCode === activeLanguageCode) {
          return {
            ...property,
            value: value,
          };
        }
        return property;
      }),
    });
  };

  const handleOnDropdownChange = (option, name) => {
    setFormData({
      ...formData,
      [name]: option,
    });
  };

  const handleOnTagRemove = (option) => {
    const updatedTags = formData.tags.filter((tag) => tag.id !== option.id);
    setFormData({
      ...formData,
      tags: updatedTags,
    });
  };
  const handleOnTagChange = (option) => {
    const existingTag = formData.tags.find((tag) => tag.id === option.id);

    if (existingTag) {
      handleOnTagRemove(option);
    } else {
      setFormData({
        ...formData,
        tags: [...formData.tags, option],
      });
    }
  };

  const handleNameInputWithMultipleLanguage = () => {
    const nameError = formData.name
      .map((name) => {
        try {
          if (name.value.trim() === "") {
            setError("name", {
              type: "manual",
              message: t("errorMessages.input.generalRequired"),
            });
            setActiveLanguageCode(name.languageCode);
            return name.languageCode;
          }
          requiredStringSchemaForMenuItem.parse(name.value);
          return null;
        } catch (error) {
          setError("name", {
            type: "manual",
            message: t("errorMessages.input.maxCharacter").replace(
              "{{max}}",
              "100"
            ),
          });

          setActiveLanguageCode(name.languageCode);
          return name.languageCode;
        }
      })
      .filter((name) => name !== null);

    return nameError;
  };

  const handleOnSubmit = () => {
    const nameError = handleNameInputWithMultipleLanguage();
    if (nameError.length > 0) {
      return;
    }

    //remove redundant uploaded images from AWS
    uploadedImages.map((image) => {
      if (!formData.otherImagesSrc.includes(image)) {
        removeImageFromDO(image);
      }
    });
    setUploadedImages([]);
    // Remove old images from AWS
    item?.otherImagesSrc.forEach((oldImage) => {
      if (!formData.otherImagesSrc.includes(oldImage)) {
        removeImageFromDO(oldImage);
      }
    });

    const modifiedModifications = formData.modifications.map((modification) => {
      const updatedModificationName = getTranslationPropertyRequestBody(
        modification.name
      );
      if (
        item?.modifications.some(
          (existingModification) => existingModification.id === modification.id
        )
      ) {
        const modifiedOptions = modification.options.map((option) => {
          const updatedOptionName = getTranslationPropertyRequestBody(
            option.name
          );
          if (
            item?.modifications
              .find(
                (existingModification) =>
                  existingModification.id === modification.id
              )
              .options.some((opt) => opt.id === option.id)
          ) {
            // Keep the existing option id
            return { ...option, name: updatedOptionName };
          }

          // Omit the option id for new options
          // eslint-disable-next-line no-unused-vars
          const { id, ...modifiedOption } = option;
          return { ...modifiedOption, name: updatedOptionName };
        });
        return {
          ...modification,
          name: updatedModificationName,
          options: modifiedOptions.map((option) => ({
            ...option,
            priceCost: option.priceCost || undefined,
            priceSell: option.priceSell === "" ? 0 : option.priceSell,
          })),
        };
      }
      // Omit the id for new modifications
      // eslint-disable-next-line no-unused-vars
      const { id, ...modifiedModification } = modification;

      // Remove id from each option in the options array
      const modifiedOptions = modifiedModification.options.map(
        // eslint-disable-next-line no-unused-vars
        ({ id, ...option }) => ({
          ...option,
          priceCost: option.priceCost || undefined,
          priceSell: option.priceSell === "" ? 0 : option.priceSell,
        })
      );

      return {
        ...modifiedModification,
        name: updatedModificationName,
        options: modifiedOptions,
      };
    });

    const updatedName = getTranslationPropertyRequestBody(formData.name);
    const updatedDescription = getTranslationPropertyRequestBody(
      formData.description
    );

    const hasNoSchedule = isEqual(formData.schedule, initialSchedule);
    const hasNoRateSchedule = isEqual(
      formData.rate.schedule,
      initialRateSchedule
    );
    let updatedFormData = { ...formData };
    if (hasNoSchedule) {
      delete updatedFormData.schedule;
    }
    if (hasNoRateSchedule) {
      // eslint-disable-next-line no-unused-vars
      const { schedule, ...updatedRate } = updatedFormData.rate;
      updatedFormData = { ...updatedFormData, rate: updatedRate };
    }
    const requestBody = {
      ...updatedFormData,
      name: updatedName,
      description: updatedDescription,
      tags: updatedFormData.tags.map((tag) => tag.id),
      modifications: modifiedModifications,
      category: formData.category.id,
      unit: updatedFormData.unit?.id,
      priceSell:
        updatedFormData.priceSell === ""
          ? 0
          : parseFloat(updatedFormData.priceSell.toFixed(2)),
      priceCost: updatedFormData.priceCost
        ? parseFloat(updatedFormData.priceCost.toFixed(2))
        : undefined,
      amount: updatedFormData.amount ? updatedFormData.amount : undefined,
      timeToMake: updatedFormData.timeToMake
        ? updatedFormData.timeToMake
        : undefined,
      calories: updatedFormData.calories ? updatedFormData.calories : undefined,
    };
    if (!coverImageLoading) {
      const updatedBody = {
        ...requestBody,
        ...(requestBody?.rate && {
          rate: {
            ...requestBody.rate,
            ...(requestBody.rate?.schedule && {
              schedule: {
                ...requestBody.rate.schedule,
                weekdays: JSON.stringify(requestBody.rate.schedule.weekdays),
              },
            }),
          },
        }),
        ...(requestBody?.schedule && {
          schedule: {
            ...requestBody.schedule,
            weekdays: JSON.stringify(requestBody.schedule.weekdays),
          },
        }),
      };
      if (isEditable) {
        onSave(updatedBody, item.id);
      } else {
        onSave(updatedBody);
      }
    }
  };

  const handleOnFileSelect = async (selectedPhotos) => {
    const selectedFilesArray = Array.from(selectedPhotos); // Convert FileList to an array
    try {
      setCoverImageLoading(true);
      const uploadedImageLocations = await Promise.all(
        selectedFilesArray.map((photo) => {
          return new Promise((resolve, reject) => {
            uploadImageToDO({
              image: photo,
              fileType: DO_FILE_TYPES.MENU,
              options: IMAGE_FILE.menuItem,
              businessId,
              onSuccess: (location) => {
                resolve(location);
              },
              onError: (error) => {
                setCoverImageError(error);
                toast.error(t("errorMessages.image"));
                setCoverImageLoading(false);
              },
            }).catch((error) => {
              reject(error);
            });
          });
        })
      );
      setUploadedImages([...uploadedImages, ...uploadedImageLocations]);
      setFormData((prevData) => ({
        ...prevData,
        coverImageSrc: formData.coverImageSrc
          ? formData.coverImageSrc
          : uploadedImageLocations[0],
        otherImagesSrc: [...uploadedImageLocations, ...formData.otherImagesSrc],
      }));
    } catch (error) {
      setCoverImageError(error);
      toast.error(t("errorMessages.image"));
    }
    setCoverImageLoading(false);
  };

  const handleOnRemoveImage = (image) => {
    const updatedImages = formData.otherImagesSrc.filter(
      (otherImage) => otherImage !== image
    );

    if (formData.coverImageSrc === image) {
      setFormData({
        ...formData,
        otherImagesSrc: updatedImages,
        coverImageSrc: updatedImages.length === 0 ? "" : updatedImages[0],
      });
    } else {
      setFormData({
        ...formData,
        otherImagesSrc: updatedImages,
      });
    }
  };

  const handleOnChangeCoverImage = (image) => {
    setFormData({
      ...formData,
      coverImageSrc: image,
    });
  };
  const handleOnAddModification = () => {
    setOpenSlideModification(true);
    setSelectedModification(null);
  };

  const handleOnEditModification = (modification) => {
    setOpenSlideModification(true);
    setSelectedModification(modification);
  };

  const handleOnDeleteModification = (id) => {
    setFormData({
      ...formData,
      modifications: formData.modifications.filter(
        (modification) => modification.id !== id
      ),
    });
  };

  const handleOnDelete = () => {
    onDelete(item.id);
  };
  const handleOnPublishModeChange = () => {
    setFormData({
      ...formData,
      isPublished: !formData.isPublished,
    });
  };

  const handleOnSaveModifications = (activeModification, id) => {
    let updatedModifications;
    if (id) {
      updatedModifications = formData.modifications.map((modification) => {
        if (id === modification.id) return activeModification;
        return modification;
      });
    } else {
      updatedModifications = [
        { id: new Date().getTime(), ...activeModification },
        ...formData.modifications,
      ];
    }
    setFormData({
      ...formData,
      modifications: updatedModifications,
    });
  };
  const setRateSchedule = (rate) => {
    setFormData({ ...formData, rate });
  };

  const handleOnChangeRate = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      rate: {
        ...formData.rate,
        isFixed: value === "true",
      },
    });
  };

  const handleOnChangeRateEnabled = () => {
    setFormData({
      ...formData,
      rate: {
        ...formData.rate,
        isEnabled: !formData.rate.isEnabled,
      },
    });
  };

  const handleOnCancelCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
  };

  const handleOnConfirmCloseModal = () => {
    setOpenSlide(false);
    setOpenSlideConfirmCloseModal(false);
  };

  const AddMenuItemModalHeader = (
    <div className="AddMenuItemModalHeader">
      <h3 className="AddMenuItemModalHeaderTitle SemiBold">
        {isEditable
          ? t("buttons.editForModal", { title: title })
          : t("buttons.addForModal", { title: title })}
      </h3>
      <div className="AddItemModalHeaderTitlePublishMode">
        <h6 className="h7">
          {formData.isPublished
            ? t("common.published")
            : t("common.unPublished")}
        </h6>
        <Switch
          isChecked={formData.isPublished}
          onChange={handleOnPublishModeChange}
        />
      </div>
      {isEditable && (
        <DeleteButton
          onClick={handleOnDelete}
          setOpenSlide={setOpenSlide}
          showAccessDeniedModal={showDeniedModal}
          {...(showDeniedModal && { className: "isDisabled" })}
        />
      )}
      <IconButton
        onClick={() => {
          if (coverImageLoading || !isEqual(formData, formDataInitial)) {
            return setOpenSlideConfirmCloseModal(true);
          }
          setOpenSlide(false);
        }}
        svgComponent={<IconClose />}
      />
    </div>
  );

  const nameAndDescriptionContent = (
    <>
      <InputControl
        labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
        type="text"
        required
        name="name"
        value={getActiveLanguageValue(formData.name, activeLanguageCode)}
        placeholder={t("inputs.name")}
        className="AddMenuItemModalBodyFormNameInput"
        error={
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => (
              <p className="h7 error-message">{message}</p>
            )}
          />
        }
        hasError={errors.name}
        func={{
          ...register("name", {
            onChange: (e) => handleOnMultiLanguageInputChange(e, "name"),
          }),
        }}
      />

      {addItemMethod === "DETAIL" && (
        <InputControl
          labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
          textarea
          name="description"
          value={getActiveLanguageValue(
            formData.description,
            activeLanguageCode
          )}
          placeholder={t("inputs.description")}
          className="AddMenuItemModalBodyFormDescriptionInput"
          error={
            <ErrorMessage
              errors={errors}
              name="description"
              render={({ message }) => (
                <p className="h7 error-message">{message}</p>
              )}
            />
          }
          hasError={errors.description}
          func={{
            ...register("description", {
              onChange: (e) =>
                handleOnMultiLanguageInputChange(e, "description"),
            }),
          }}
        />
      )}
    </>
  );

  const nameAndDescriptionTabs = ["name", "description"];
  const setTabProperties = (properties, tabs) => {
    const newProperties = properties.map((property) => {
      return property.filter((item) => item.value !== null);
    });
    const updatedProperties = _.cloneDeep(formData);
    tabs.forEach((tab, tabIndex) => {
      const keys = tab.split(".");
      let currentLevel = updatedProperties;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          currentLevel[key] = newProperties[tabIndex];
        } else {
          updatedProperties[key] = updatedProperties[key] || {};
          currentLevel = currentLevel[key];
        }
      });
    });
    setFormData(updatedProperties);
  };

  const GeneralAddMenuItemForm = (
    <>
      <InputControl
        labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
        type="number"
        name="priceSell"
        required
        value={formData.priceSell}
        placeholder={t("inputs.sell")}
        hasError={errors.priceSell}
        func={{
          ...register("priceSell", {
            setValueAs: (value) =>
              value === "" ? undefined : parseFloat(value),
            onChange: (e) => {
              handleOnInputChange(e);
            },
          }),
        }}
        definitionText={currencyCode}
        error={
          <ErrorMessage
            errors={errors}
            name="priceSell"
            render={({ message }) => (
              <p className="h7 error-message">{message}</p>
            )}
          />
        }
      />
      <Dropdown
        placeholder={t("menu.category.category")}
        name="category"
        value={formData.category}
        isOptionRequired
        options={categories}
        isMultiLanguage
        onChange={(category) => {
          handleOnDropdownChange(category, "category");
        }}
      />
      <div className="AddMenuItemModalBodyPhotos">
        <h5 className="Medium">
          {t("image.images")}{" "}
          {formData.otherImagesSrc.length > 0 && (
            <span className="AddMenuItemModalBodyPhotosAdditionalInfo">
              {`(${t("image.selectCover")})`}
            </span>
          )}
        </h5>
        <div className="AddMenuItemModalBodyPhotoAdd">
          <div className="AddMenuItemModalBodyPhotoAddNew">
            <div className="AddMenuItemModalBodyPhotoAddNewComponent">
              <AddPhotoButton onFileSelect={handleOnFileSelect} />
            </div>
          </div>

          {(formData.otherImagesSrc.length > 0 || coverImageLoading) && (
            <div className="AddMenuItemModalBodyPhotoAddExisting">
              <div className="AddMenuItemModalBodyPhotoAddExistingPhotos">
                {coverImageLoading && !coverImageError && <Spinner />}
                {formData.otherImagesSrc.map((photo) => (
                  <Photo
                    key={photo}
                    activeImage={createDOBucketName(formData.coverImageSrc)}
                    image={createDOBucketName(photo)}
                    onClose={() => handleOnRemoveImage(photo)}
                    onClick={() => handleOnChangeCoverImage(photo)}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );

  const AddMenuItemModalBody = (
    <div className={`AddMenuItemModalBody ${addItemMethod.toLowerCase()}`}>
      <ModificationSettings
        item={selectedModification}
        setOpenSlide={setOpenSlideModification}
        mainElementRef={mainElementRefModification}
        openSlide={openSlideModification}
        onSave={handleOnSaveModifications}
        setOutsideClickAction={setOutsideClickActionModification}
      />
      <div className="Tab AddMenuItemModalBodyMethodTab typeA">
        <div className="Tabs">
          <div className="TabsExisting">
            {ADD_ITEM_METHOD_ENUMS.map((option, index) => (
              <div
                onClick={() => setAddItemMethod(option.method)}
                key={index}
                className={cx("TabItemContainer", {
                  isActive: addItemMethod === option.method,
                })}
              >
                <div className="TabItem">
                  <h6 className="SemiBold">
                    {t(`info.${option.method.toLowerCase()}`)}
                  </h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <form
        className="AddMenuItemModalBodyForm"
        onSubmit={(e) => e.preventDefault()}
      >
        <Language
          content={nameAndDescriptionContent}
          activeLanguageCode={activeLanguageCode}
          setActiveLanguageCode={setActiveLanguageCode}
          properties={[formData.name, formData.description]}
          setProperties={(properties) =>
            setTabProperties(properties, nameAndDescriptionTabs)
          }
        ></Language>
        {addItemMethod === "GENERAL" ? (
          <>{GeneralAddMenuItemForm}</>
        ) : (
          <>
            <div className="AddMenuItemModalBodyFormBox">
              <div className="AddMenuItemModalBodyFormPriceAndDiscount">
                <div className="AddMenuItemModalBodyFormPrice">
                  <InputControl
                    labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                    type="number"
                    name="priceSell"
                    required
                    value={formData.priceSell}
                    placeholder={t("inputs.sell")}
                    className="AddMenuItemModalBodyFormInputHalfWidth"
                    hasError={errors.priceSell}
                    func={{
                      ...register("priceSell", {
                        setValueAs: (value) =>
                          value === "" ? undefined : parseFloat(value),
                        onChange: (e) => handleOnInputChange(e),
                      }),
                    }}
                    definitionText={currencyCode}
                    error={
                      <ErrorMessage
                        errors={errors}
                        name="priceSell"
                        render={({ message }) => (
                          <p className="h7 error-message">{message}</p>
                        )}
                      />
                    }
                  />
                  <InputControl
                    labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                    type="number"
                    name="priceCost"
                    value={formData.priceCost}
                    hasError={errors.priceCost}
                    definitionText={currencyCode}
                    func={{
                      ...register("priceCost", {
                        setValueAs: (value) =>
                          value === "" ? undefined : parseFloat(value),
                        onChange: (e) => handleOnInputChange(e),
                      }),
                    }}
                    error={
                      <ErrorMessage
                        errors={errors}
                        name="priceCost"
                        render={({ message }) => (
                          <p className="h7 error-message">{message}</p>
                        )}
                      />
                    }
                    placeholder={t("inputs.cost")}
                    className="AddMenuItemModalBodyFormInputHalfWidth"
                  />
                </div>
                <div className="RateExtraComponent">
                  <div className="RateExtraComponentTitle">
                    <Switch
                      onChange={handleOnChangeRateEnabled}
                      isChecked={formData.rate.isEnabled}
                      type={ENUMS_SWITCH.types.TYPE_A}
                    />
                    <h6 className="Medium">{t("buttons.applyDiscount")}</h6>
                  </div>
                  {formData.rate.isEnabled && (
                    <div className="RateExtraComponentBody">
                      <div className="RateExtraComponentBodyIsFixed">
                        <RadioSelection
                          onChange={handleOnChangeRate}
                          name="isFixed"
                          isChecked={!formData.rate.isFixed}
                          value={false}
                          label={t("inputs.notFixed")}
                        />
                        <RadioSelection
                          onChange={handleOnChangeRate}
                          name="isFixed"
                          isChecked={formData.rate.isFixed}
                          value={true}
                          label={t("inputs.fixed")}
                        />
                      </div>
                      <InputControl
                        labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                        type="number"
                        name="rate"
                        value={formData.rate.amount || ""}
                        placeholder={t("inputs.discount")}
                        definitionText={
                          formData.rate.isFixed ? currencyCode : "%"
                        }
                        className="RateExtraComponentItemInput"
                        hasError={errors.rate}
                        func={{
                          ...register("rate", {
                            setValueAs: (value) =>
                              value === "" ? undefined : parseFloat(value),
                            onChange: (e) => handleOnRateChange(e),
                          }),
                        }}
                        error={
                          <ErrorMessage
                            errors={errors}
                            name="rate"
                            render={({ message }) => (
                              <p className="h7 error-message">{message}</p>
                            )}
                          />
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
              {formData.rate.isEnabled && (
                <DateAccordion
                  setIsAccordionOpen={setIsDiscountScheduleAccordionOpen}
                  isAccordionOpen={isDiscountScheduleAccordionOpen}
                  setFormData={setRateSchedule}
                  formData={formData.rate}
                  title={t("menu.item.scheduleDiscount")}
                />
              )}
            </div>
            <div className="AddMenuItemModalBodyFormBox AddMenuItemModalBodyFormBoxDropdown">
              <Dropdown
                placeholder={t("menu.category.category")}
                name="category"
                value={formData.category}
                isOptionRequired
                options={categories}
                isMultiLanguage
                onChange={(category) => {
                  handleOnDropdownChange(category, "category");
                }}
              />
              <Dropdown
                isMultiple
                placeholder={t("menu.tag.tags")}
                name="tag"
                value={formData.tags}
                options={tags}
                onChange={handleOnTagChange}
                isMultiLanguage
              />
            </div>
            <div className="AddMenuItemModalBodyPhotos">
              <h5 className="Medium">
                {t("image.images")}{" "}
                {formData.otherImagesSrc.length > 0 && (
                  <span className="AddMenuItemModalBodyPhotosAdditionalInfo">
                    {`(${t("image.selectCover")})`}
                  </span>
                )}
              </h5>
              <div className="AddMenuItemModalBodyPhotoAdd">
                <div className="AddMenuItemModalBodyPhotoAddNew">
                  <div className="AddMenuItemModalBodyPhotoAddNewComponent">
                    <AddPhotoButton onFileSelect={handleOnFileSelect} />
                  </div>
                </div>

                {(formData.otherImagesSrc.length > 0 || coverImageLoading) && (
                  <div className="AddMenuItemModalBodyPhotoAddExisting">
                    <div className="AddMenuItemModalBodyPhotoAddExistingPhotos">
                      {coverImageLoading && !coverImageError && <Spinner />}
                      {formData.otherImagesSrc.map((photo) => (
                        <Photo
                          key={photo}
                          activeImage={createDOBucketName(
                            formData.coverImageSrc
                          )}
                          image={createDOBucketName(photo)}
                          onClose={() => handleOnRemoveImage(photo)}
                          onClick={() => handleOnChangeCoverImage(photo)}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="AddMenuItemModalBodyFormBox">
              <div className="AddMenuItemModalBodyFormBoxRow">
                <InputControl
                  labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                  type="number"
                  name="calories"
                  value={formData.calories}
                  func={{
                    ...register("calories", {
                      setValueAs: (value) =>
                        value === "" ? undefined : parseFloat(value),
                      onChange: (e) => handleOnInputChange(e),
                    }),
                  }}
                  hasError={errors.calories}
                  error={
                    <ErrorMessage
                      errors={errors}
                      name="calories"
                      render={({ message }) => (
                        <p className="h7 error-message">{message}</p>
                      )}
                    />
                  }
                  placeholder={t("inputs.calories")}
                  className="AddMenuItemModalBodyFormInputHalfWidth"
                  definitionText={t("common.kcal")}
                />

                <InputControl
                  labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                  type="number"
                  name="timeToMake"
                  value={formData.timeToMake}
                  hasError={errors.timeToMake}
                  func={{
                    ...register("timeToMake", {
                      setValueAs: (value) =>
                        value === "" ? undefined : parseFloat(value),
                      onChange: (e) => handleOnInputChange(e),
                    }),
                  }}
                  error={
                    <ErrorMessage
                      errors={errors}
                      name="timeToMake"
                      render={({ message }) => (
                        <p className="h7 error-message">{message}</p>
                      )}
                    />
                  }
                  placeholder={t("inputs.time")}
                  className="AddMenuItemModalBodyFormInputHalfWidth"
                  definitionText={t("common.time.min")}
                />
              </div>
              <div className="AddMenuItemModalBodyFormBoxRow">
                <InputControl
                  labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                  type="number"
                  name="amount"
                  value={formData.amount}
                  placeholder={t("inputs.number")}
                  hasError={errors.amount}
                  className="AddMenuItemModalBodyFormInputHalfWidth"
                  infoText={t("info.itemAmount")}
                  func={{
                    ...register("amount", {
                      setValueAs: (value) =>
                        value === "" ? undefined : parseFloat(value),
                      onChange: (e) => handleOnInputChange(e),
                    }),
                  }}
                  error={
                    <ErrorMessage
                      errors={errors}
                      name="amount"
                      render={({ message }) => (
                        <p className="h7 error-message">{message}</p>
                      )}
                    />
                  }
                />
                <Dropdown
                  placeholder={t("inputs.unit")}
                  name="unit"
                  value={formData.unit}
                  hasError={errors.unit}
                  options={units}
                  isOptionRequired
                  {...register("unit")}
                  onChange={(unit) => {
                    handleOnDropdownChange(unit, "unit");
                  }}
                  className="AddMenuItemModalBodyFormInputHalfWidth"
                  error={
                    <ErrorMessage
                      errors={errors}
                      name="unit"
                      render={({ message }) => (
                        <p className="h7 error-message">{message}</p>
                      )}
                    />
                  }
                />
              </div>
            </div>
            <div className="AddMenuItemModalBodyFormBox">
              <DateAccordion
                setIsAccordionOpen={setIsItemScheduleAccordionOpen}
                isAccordionOpen={isItemScheduleAccordionOpen}
                setFormData={setFormData}
                formData={formData}
              />
            </div>
          </>
        )}
      </form>
      {addItemMethod === "DETAIL" && (
        <div className="AddMenuItemModalBodyFormBox">
          <div className="AddMenuItemModalBodyModifications">
            <div className="AddMenuItemModalBodyModificationsTitle">
              <h5 className="Medium">{t("modification.modifications")}</h5>
              <AddNewButton
                onClick={handleOnAddModification}
                type={ENUMS_ADD_NEW_BUTTON.types.TYPE_B}
              />
            </div>
            {formData.modifications.length > 0 ? (
              <div className="AddMenuItemModalBodyModificationsList">
                {formData.modifications.map((modification) => (
                  <ModificationsMenu
                    key={modification.id}
                    data={modification}
                    selectedOptions={getBasketModificationsWithDefaultValues(
                      formData.modifications
                    ).find((options) => options.id === modification.id)}
                    hasControls
                    onEdit={() => {
                      handleOnEditModification(modification);
                    }}
                    onDelete={() => {
                      handleOnDeleteModification(modification.id);
                    }}
                    isReadOnly
                  />
                ))}
              </div>
            ) : (
              <h6 className="Medium AddMenuItemModalBodyNoModifications">
                {t("modification.noModifications")}
              </h6>
            )}
          </div>
        </div>
      )}
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_C}
        title={t("modal.warningModalTitleUnsavedChanges")}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        description={t("modal.warningModalDescription")}
        onCancel={(e) => handleOnCancelCloseModal(e)}
        onConfirm={(e) => handleOnConfirmCloseModal(e)}
        openSlide={openSlideConfirmCloseModal}
      />
    </div>
  );

  const AddMenuItemModalFooter = (
    <PrimaryButton
      onClick={handleSubmit(handleOnSubmit)}
      text={t("buttons.save")}
      isLoading={isLoading}
    />
  );

  return (
    <Modal
      header={AddMenuItemModalHeader}
      body={AddMenuItemModalBody}
      footer={AddMenuItemModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    />
  );
};

AddMenuItemModal.propTypes = {
  /**
   * The ref for the modal
   */
  mainElementRef: PropTypes.object,

  /**
   * The function called to set the open slide state
   */
  setOpenSlide: PropTypes.func,

  /**
   * The item data to display and edit
   */
  item: PropTypes.shape({
    id: PropTypes.number,
    schedule: PropTypes.object,
    value: PropTypes.string,
    name: PropTypes.array,
    description: PropTypes.array,
    priceSell: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    priceCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    calories: PropTypes.number,
    timeToMake: PropTypes.number,
    amount: PropTypes.number,
    unit: PropTypes.object,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.array,
      })
    ),
    coverImageSrc: PropTypes.string,
    otherImagesSrc: PropTypes.array,
    modifications: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
    category: PropTypes.object,
    isPublished: PropTypes.bool,
    rate: PropTypes.object.isRequired,
    isFixed: PropTypes.bool,
  }),

  /**
   * Flag to determine if the modal is open
   */
  openSlide: PropTypes.bool,

  /**
   * The function called when the form is submitted to save the data
   */
  onSave: PropTypes.func,

  /**
   * The function called to delete the item
   */
  onDelete: PropTypes.func,
  /**
   * The title to display
   */
  title: PropTypes.string.isRequired,

  /**
   * The active category
   */
  selectedCategory: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.array,
  }),

  /**
   * The function used for custom modal outside click operation
   */
  setOutsideClickAction: PropTypes.func,

  /**
   * The flag to determine if the form is loading
   * */
  isLoading: PropTypes.bool,
  showDeniedModal: PropTypes.bool,
};

export default AddMenuItemModal;
