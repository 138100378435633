import { checkValidDateRange } from "utils/helpers";

export const menuItemHasDiscountBySchedule = (item) => {
  const today = new Date();
  const todayDayOfWeek = today
    .toLocaleDateString("en-US", { weekday: "long" })
    .toLowerCase();

  const from = new Date(item.rate.schedule?.from);
  const to = new Date(item.rate.schedule?.to);
  const isValidDateRange = checkValidDateRange(from, to, today);

  const todaySchedule =
    item.rate.schedule?.weekdays &&
    item.rate.schedule?.weekdays[todayDayOfWeek];

  const isWorkingToday =
    todaySchedule &&
    todaySchedule.isWorking &&
    today >= new Date(today.toDateString() + " " + todaySchedule.from) &&
    today <= new Date(today.toDateString() + " " + todaySchedule.to);

  return isWorkingToday && isValidDateRange;
};
