import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import { ReactComponent as MiniPlus } from "assets/icons/plus/miniPlus.svg";
import { ReactComponent as HappyBasket } from "assets/icons/basket/HappyBasket.svg";

import "./AnimatedAddButton.scss";

export const ENUMS = {
  name: "AnimationAddButton",
  types: {
    SMALL: "Small",
    MEDIUM: "Medium",
  },
};

const AnimatedAddButton = ({ id, value, onClick, size = ENUMS.types.MEDIUM, isTriggered }) => {
  const { t } = useTranslation();
  const [isClicked, setIsClicked] = useState(false);
  const [isClosing, setIsClosing] = useState(false);


  useEffect(() => {
    if (isTriggered) {
      setIsClicked(true);
      setTimeout(() => {
        setIsClicked(false);
        setIsClosing(true);
        setTimeout(() => setIsClosing(false), 600);
      }, 800);
    }
  }, [isTriggered]);

  const handleOnClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (isClicked || isClosing) return;

      onClick(id, value);
      setIsClicked(true);

      setTimeout(() => {
        setIsClicked(false);
        setIsClosing(true);
        setTimeout(() => setIsClosing(false), 600);
      }, 800);
    },
    [id, value, onClick, isClicked, isClosing]
  );

  return (
    <button
      onClick={handleOnClick}
      className={cx("AnimatedAddButton", size, {
        clicked: isClicked,
        closing: isClosing,
      })}
      type="button"
    >
      {isClicked ? (
        <>
          <HappyBasket className="AnimatedAddButtonIcon HappyBasketIcon" />
          <h6 className="h7 SemiBold AnimatedAddButtonText">
            {t("basket.added")}
          </h6>
        </>
      ) : (
        <MiniPlus className="AnimatedAddButtonIcon" />
      )}
      {value !== 0 && <span className="AnimatedAddButtonBadge">{value}</span>}
    </button>
  );
};

AnimatedAddButton.propTypes = {
  id: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf([ENUMS.types.SMALL, ENUMS.types.MEDIUM]).isRequired,
  isTriggered: PropTypes.bool,
};

export default AnimatedAddButton;
