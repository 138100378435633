import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useGuestLayout } from "pages/client/menu-v2/GuestLayoutContext";

import styles from "./HamburgerActionButton.module.scss";

const HamburgerActionButton = ({ route }) => {
  const { icon, text, path, notification } = route;
  const navigate = useNavigate();
  const { closeHamburger } = useGuestLayout();
  const navigateHandler = () => {
    closeHamburger();
    navigate(path);
  };

  const displayCount = notification > 9 ? "+9" : notification;
  return (
    <button className={styles.Container} onClick={navigateHandler}>
      <div className={styles.Label}>
        {icon}
        <h5 className="SemiBold">{text}</h5>
      </div>
      {notification && (
        <div className={styles.Notification}>
          <h6 className="Medium h7">{displayCount}</h6>
        </div>
      )}
    </button>
  );
};
HamburgerActionButton.propTypes = {
  route: PropTypes.shape({
    icon: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    notification: PropTypes.number,
  }),
};
export default HamburgerActionButton;
