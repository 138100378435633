import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import { ReactComponent as Clock } from "assets/icons/clock/Clock.svg";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import AnimatedAddButton, {
  ENUMS as ENUMS_ANIMATION,
} from "components/buttons/animated-add-button/AnimatedAddButton";
import FavoritesIconButton, {
  ENUMS as ENUMS_FAVORITES_ICON_BUTTON,
} from "components/buttons/favorites-icon-button/FavoritesIconButton";

import useLanguage from "utils/hooks/useLanguage";
import Tag, { ENUMS as ENUMS_TAG } from "components/elements/tag/Tag";
import useTags from "utils/hooks/useTags";
import { createDOBucketName } from "utils/DO-Spaces";
import {
  calculateItemPriceWithDefaultModificationPrice,
  calculateMenuItemPriceBySchedule,
} from "utils/general";

import styles from "./MenuCard.module.scss";

export const ENUMS = {
  name: "MenuCard",
  types: {
    SMALL: "Small",
    MEDIUM: "Medium",
    LARGE: "Large",
    WITHOUT_IMAGE: "WithoutImage",
  },
};

const MenuCard = ({
                    menuItem,
                    onFavorite,
                    onAddToBasket,
                    type = ENUMS.types.SMALL,
                    isFavorite = false,
                    onClick,
                  }) => {
  const { id, name, coverImageSrc, timeToMake } = menuItem;
  const { allTags } = useTags(menuItem);
  const { displayDataByLanguage } = useLanguage();
  const { t } = useTranslation();

  const discountPrice = calculateMenuItemPriceBySchedule(menuItem);
  const menuItemPrice =
      calculateItemPriceWithDefaultModificationPrice(menuItem);

  const handleFavorite = () => onFavorite(id);

  return (
      <div onClick={onClick} className={cx(styles.MenuCard, styles[type])}>
        <div className={styles.ImageContainer}>
          <ImageWithPlaceholder
              imageSource={createDOBucketName(coverImageSrc)}
              placeholder={IMAGE_ITEM_PLACEHOLDER}
              alt={displayDataByLanguage(name)}
              className={styles.Image}
          />
          {type !== ENUMS.types.SMALL && (
              <div className={styles.FavoriteAndTimeToMake}>
                {timeToMake !== 0 && (
                    <h6 className={`${styles.TimeToMake} h8 Medium`}>
                      <Clock /> {timeToMake} {t("common.time.min")}
                    </h6>
                )}
                <FavoritesIconButton
                    id={id}
                    type={ENUMS_FAVORITES_ICON_BUTTON.types.TYPE_F}
                    onClick={handleFavorite}
                    value={isFavorite}
                />
              </div>
          )}
        </div>
        <div className={styles.DetailContainer}>
          <h4 className={cx(styles.Title, "SemiBold")}>
            {displayDataByLanguage(name)}
          </h4>
          {type === ENUMS.types.WITHOUT_IMAGE && (
              <div className={styles.Favorite}>
                <FavoritesIconButton
                    id={id}
                    type={ENUMS_FAVORITES_ICON_BUTTON.types.TYPE_G}
                    onClick={handleFavorite}
                    value={isFavorite}
                />
              </div>
          )}

          {allTags.length > 0 && (
              <div className={styles.Tags}>
                <Tag
                    items={allTags}
                    type={ENUMS_TAG.types.TYPE_F}
                    showTime={type === ENUMS.types.WITHOUT_IMAGE}
                />
              </div>
          )}
          <div className={styles.Footer}>
            <div className={styles.Price}>
              {discountPrice !== null && (
                  <Price
                      type={ENUMS_PRICE.types.PRICE_LINE_THROUGH}
                      value={menuItemPrice}
                  />
              )}
              <Price
                  type={ENUMS_PRICE.types.PRICE_M_SEMI_BOLD}
                  value={
                    discountPrice !== null
                        ? menuItemPrice + discountPrice
                        : menuItemPrice
                  }
              />

            </div>

            <AnimatedAddButton
                id={id}
                onClick={() => onAddToBasket(menuItem)}
                value={0}
                size={ENUMS_ANIMATION.types.MEDIUM}
            />
          </div>
        </div>
      </div>
  );
};

MenuCard.propTypes = {
  menuItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.array.isRequired,
    coverImageSrc: PropTypes.string,
    priceSell: PropTypes.number.isRequired,
    priceCost: PropTypes.number,
    calories: PropTypes.number,
    timeToMake: PropTypes.number,
    discountPrice: PropTypes.number,
    amount: PropTypes.number,
    unit: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  onFavorite: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isFavorite: PropTypes.bool,
  onAddToBasket: PropTypes.func.isRequired,
  type: PropTypes.oneOf([
    ENUMS.types.SMALL,
    ENUMS.types.MEDIUM,
    ENUMS.types.LARGE,
    ENUMS.types.WITHOUT_IMAGE,
  ]).isRequired,
};

export default MenuCard;
