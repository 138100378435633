import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import BasketHeroSection from "pages/client/basket/basket-hero-section/BasketHeroSection";
import { STORE_NAMES } from "utils/constants/redux";
import {
  findMenuItemByIdAndPublished,
  getBasketModificationsWithDefaultValues,
} from "utils/helpers";
import {
  calculateAllOrdersDiscountPrice,
  calculateAllOrdersPrice,
  calculateAllOrdersTotalPrice,
  calculateServiceFee,
  calculateTax,
  findItemByIdForBasket,
} from "utils/general";
import {
  ACTIONS,
  resetItems,
  updateBasket,
  updateFavorites,
  updateOrderMessage,
} from "redux/slices/basketStore";
import { setLoading } from "redux/slices/ordersStore";
import OrderInfo from "components/cards/order-info/OrderInfo";
import BasketItem from "components/elements/basket-item/BasketItem";
import CTAButton, {
  ENUMS as CTA_ENUMS,
} from "components/buttons/cta-button/CTAButton";
import InputControl from "components/admin/forms/input-control/InputControl";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import useAsync from "utils/hooks/useAsync";
import { updateOrderGuest } from "utils/api/services/order";
import EmptyState from "components/admin/empty-state/EmptyState";
import ICON_EMPTY_BASKET from "assets/icons/basket/empty-basket.svg";
import WelcomeClient from "components/welcome-client/WelcomeClient";
import useOutsideClick from "utils/hooks/useOutsideClick";
import OrderConfirm from "components/guest/guest-modal/order-confirm/OrderConfirm";
import { useMixpanel } from "utils/context-api/MixpanelContext";
import {
  MP_EVENTS,
  MP_PAGE_NAMES,
  MP_PROP_NAMES,
  MP_SOURCE_NAME,
} from "utils/constants/mixpanel";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";
import { useGuestLayout } from "pages/client/menu-v2/GuestLayoutContext";
import { TAB_BAR_HEIGHT } from "pages/client/menu-v2/tab-bar/TabBar";

import "./Basket.scss";

const Basket = () => {
  const [heroHeight, setHeroHeight] = useState(128);

  const { isTabBarHidden } = useGuestLayout();
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.basket });
  const { trackMixpanel } = useMixpanel();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const audioRef = useRef(null);
  const currency = useSelector(
    (state) => state[STORE_NAMES.menu].data.currency.code
  );
  const { id: businessId } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );
  const isLoadingOrder = useSelector(
    (state) => state[STORE_NAMES.orders]?.thunkAPIStates?.getAllOrder
  );

  const { execute: executeUpdateOrder } = useAsync(updateOrderGuest, {
    onSuccess: () => {
      const queryString = new URLSearchParams({
        ...searchParams,
        [QUERY_PARAMS.showConfetti]: true,
      }).toString();
      navigate(
        `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.dashboard}?${queryString}`
      );
      dispatch(
        resetItems({
          userId: guestId,
          actionType: ACTIONS.resetOrderItems,
        })
      );
      trackMixpanel(`${MP_EVENTS.basket.checkout}`, {
        [MP_PROP_NAMES.numOfItems]: orderItemsCount,
        [MP_PROP_NAMES.totalPrice]: totalPrice,
        [MP_PROP_NAMES.currency]: currency,
      });
    },
  });

  const { serviceFee } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const basketOrder = useSelector((state) => state[STORE_NAMES.basket].order);
  const tableId = useSelector((state) => state[STORE_NAMES.qrScan].table.id);
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const { data: menu, isLoading } = useSelector(
    (state) => state[STORE_NAMES.menu]
  );
  const [openSlideConfirm, setOpenSlideConfirm, mainElementRefConfirm] =
    useOutsideClick();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const order = orders?.find((order) => order.table.id === tableId);
  const guests = basketOrder ? basketOrder.guests : [];
  const guest = guests.find((guest) => guest.person.id === guestId);

  const isOrderEmpty = !order;
  const favoriteItems =
    menu && guest
      ? guest?.favoriteItems
          .map((id) => findItemByIdForBasket(id, menu))
          .filter((item) => item !== null)
      : [];

  const orderItems = guest?.orderItems || [];

  const filteredOrderItems =
    orderItems.filter((orderItem) => {
      const menuItem =
        orderItem.item && findItemByIdForBasket(orderItem.item.id, menu);
      return menuItem?.isPublished && !menuItem?.isArchived;
    }) || [];

  const orderItemsCount = filteredOrderItems?.reduce(
    (sum, orderItem) => sum + orderItem.count,
    0
  );
  const isBasketEmpty = orderItemsCount === 0;

  const convertedGuestsInBasket = useMemo(() => {
    return [
      {
        ...guest,
        orderItems: filteredOrderItems
          ? filteredOrderItems.map((orderItem) => {
              return {
                ...orderItem,
                item: findMenuItemByIdAndPublished(
                  menu.categories,
                  orderItem.item,
                  dispatch,
                  guest.id
                ),
              };
            })
          : [],
      },
    ];
  }, [filteredOrderItems]);

  const subtotal =
    filteredOrderItems.length > 0
      ? calculateAllOrdersPrice(convertedGuestsInBasket)
      : 0;

  const discountPrice =
    filteredOrderItems.length > 0
      ? calculateAllOrdersDiscountPrice(convertedGuestsInBasket)
      : 0;

  const tax = menu ? calculateTax(subtotal, menu.extraFees.tax) : 0;

  const serviceFeeTotal = menu
    ? calculateServiceFee(subtotal, discountPrice, serviceFee)
    : 0;

  const totalPrice = subtotal
    ? calculateAllOrdersTotalPrice(
        subtotal,
        menu?.extraFees,
        null,
        serviceFeeTotal,
        discountPrice
      )
    : 0;
  const handleRemoveFavItem = (e, menuItem) => {
    e.stopPropagation();
    dispatch(
      updateFavorites({
        userId: guestId,
        menuItemID: menuItem.id,
      })
    );
  };

  const handleAddFavItem = (e, menuItem) => {
    e.stopPropagation();
    const modifications = getBasketModificationsWithDefaultValues(
      menuItem.modifications
    );
    const filteredModifications = modifications.filter((modification) => {
      return modification.options.length !== 0;
    });
    dispatch(
      updateBasket({
        menuItem: {
          id: menuItem.id,
          modifications: filteredModifications.map((modification) => {
            return {
              id: modification.id,
              options: modification.options.map((option) => {
                return {
                  id: option.id,
                  count: option.count ?? 1,
                };
              }),
            };
          }),
        },
        count: 1,
        userId: guestId,
      })
    );
  };

  const onBasketItemClick = (menuItemId, basketIndex) => {
    const itemName = menu.categories
      .flatMap(({ menuItems }) => menuItems)
      .find((item) => item.id === menuItemId).name[0].value;
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItem}/${menuItemId}?isEditMode=true&basketIndex=${basketIndex}&${QUERY_PARAMS.from}=${pathname}`
    );
    trackMixpanel(`${MP_EVENTS.pageView.guest.menuItem}`, {
      [MP_PROP_NAMES.itemId]: menuItemId,
      [MP_PROP_NAMES.itemName]: itemName,
      [MP_PROP_NAMES.source]: MP_SOURCE_NAME.basket,
    });
  };
  const handleRemoveBasketItem = (menuItem) => {
    dispatch(
      updateBasket({
        menuItem: {
          ...menuItem,
        },
        count: null,
        userId: guestId,
      })
    );
  };
  const handleAddBasketItem = (menuItem) => {
    dispatch(
      updateBasket({
        menuItem: {
          ...menuItem,
        },
        count: 1,
        userId: guestId,
      })
    );
  };
  const handleDecreaseBasketItem = (menuItem) => {
    dispatch(
      updateBasket({
        menuItem: {
          ...menuItem,
        },
        count: -1,
        userId: guestId,
      })
    );
  };

  const handleOnClick = (e) => {
    e.stopPropagation();
    setOpenSlideConfirm(true);
  };

  const handleOnConfirm = async (e) => {
    e.stopPropagation();
    setOpenSlideConfirm(false);
    try {
      await handleConfirmOrder();
    } catch (error) {
      console.error("Order confirmation failed:", error);
    }
  };

  const handleOnCancel = (e) => {
    e.stopPropagation();
    setOpenSlideConfirm(false);
  };

  const handleConfirmOrder = async () => {
    dispatch(setLoading(true));
    const allOrderItems = [...filteredOrderItems];

    const updatedBasketOrder = {
      ...basketOrder,
      guests: [
        {
          ...guest,
          orderMessage:
            guest.orderMessage.trim() !== "" ? guest.orderMessage : undefined,
          orderItems: allOrderItems,
        },
      ],
      hasNewOrder: true,
    };

    await executeUpdateOrder({
      businessId,
      order: updatedBasketOrder,
      id: order.id,
    });

    await dispatch(setLoading(false));

    // audioRef.current = new Audio(CheckoutSound);
    // audioRef.current.currentTime = 0;
    // audioRef.current.play().catch((error) => {
    //   console.error("Audio playback failed: ", error);
    // });
  };

  const handleBackToMenu = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
    );
  };

  if (isLoading) {
    return <WelcomeClient />;
  }
  const resultHeight =146+ heroHeight ;
  return (
    <div className={cx("Basket", { isBasketEmpty })}>
      <BasketHeroSection
        favoriteItems={favoriteItems}
        onClose={handleRemoveFavItem}
        onAdd={handleAddFavItem}
        setHeroHeight={setHeroHeight}
      />
      {isBasketEmpty ? (
        <div style={{ height: `calc(100% - ${resultHeight}px)`}}>
          <EmptyState
            title={t("basket.emptyBasketTitle")}
            description={t("basket.emptyBasketDescription")}
            icon={ICON_EMPTY_BASKET}
            isAdmin={false}
          />
        </div>
      ) : (
        <div className="BasketMainSection">
          <div className="OrderItemsWrapper">
            <h4 className="BasketAllItems">{t("navbarRoutes.allItems")}</h4>
            {filteredOrderItems &&
              filteredOrderItems.map((orderItem, index) => {
                return (
                  <BasketItem
                    key={index}
                    menuItem={orderItem.item}
                    basketIndex={index}
                    menuCategories={menu.categories}
                    onAdd={handleAddBasketItem}
                    onRemove={handleRemoveBasketItem}
                    onClick={onBasketItemClick}
                    onDecrease={handleDecreaseBasketItem}
                    count={orderItem.count}
                    guestId={guestId}
                  />
                );
              })}
          </div>
          <div className="AddMessageForTheRestaurant">
            <h6 className="Medium">{t("basket.addMessage")}</h6>
            <div className="AddMessageTextareaContainer">
              <InputControl
                placeholder={t("basket.messagePlaceholder")}
                value={guest?.orderMessage || ""}
                onChange={(e) =>
                  dispatch(
                    updateOrderMessage({
                      userId: guestId,
                      message: e.target.value,
                    })
                  )
                }
                noLabelFloating
                textarea
              />
            </div>
          </div>
          <OrderInfo
            serviceFee={serviceFee}
            totalPrice={totalPrice}
            subtotal={subtotal}
            tax={tax}
            serviceFeeTotal={serviceFeeTotal}
            discountPrice={discountPrice}
          />
        </div>
      )}
      <CTAButton
        className={cx("ConfirmOrderBtn", { isBasketEmpty })}
        style={{ bottom: isTabBarHidden ? 30 : 10 + TAB_BAR_HEIGHT }}
        onClick={
          !isBasketEmpty && !isOrderEmpty ? handleOnClick : handleBackToMenu
        }
        name={
          !isBasketEmpty && !isOrderEmpty
            ? t("buttons.confirmOrder")
            : t("buttons.backToMenu")
        }
        type={CTA_ENUMS.types.TYPE_B}
        price={totalPrice}
        // disabled={isOrderEmpty}
        isLoading={isLoadingOrder}
      />
      <OrderConfirm
        orderItems={filteredOrderItems}
        menuCategories={menu.categories}
        guestId={guestId}
        title={t("modal.areYouSureYouWantToPlaceThisOrder")}
        checkboxText={t("modal.yesIConfirmMyOrderAndTakeResponsibilityToPay")}
        mainElementRefConfirm={mainElementRefConfirm}
        openSlide={openSlideConfirm}
        onCancel={(e) => handleOnCancel(e)}
        onConfirm={(e) => handleOnConfirm(e)}
      />
    </div>
  );
};

export default Basket;
