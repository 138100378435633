import React, { createContext, useContext, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import Hamburger from "./hamburger/Hamburger";
import TabBar, { generateTabRoutes } from "./tab-bar/TabBar";
import { useLocation, useParams } from "react-router-dom";
import useManageTabBarAndScrollTopWhenScrolling from "./useManageTabBarAndScrollTopWhenScrolling";
import MenuFilterModal from "./menu-filter-modal/MenuFilterModal";
import If from "components/if/If";

import styles from "./index.module.scss";

const GuestLayoutContext = createContext();

const GuestLayoutProvider = ({ children }) => {
  const { isTabBarHidden, showTabBar, isScrollTopHidden } =
    useManageTabBarAndScrollTopWhenScrolling({
      scrollThresholdForTabBar: 44,
    });
  const { businessId } = useParams();
  const { pathname } = useLocation();
  const [isHamburgerVisible, setIsHamburgerVisible] = useState(false);

  const TAB_ROUTES = Object.values(generateTabRoutes(businessId))
    .filter((route) => route.showTabBarInThisRoute)
    .flatMap((route) => route.path);

  const openHamburger = () => {
    setIsHamburgerVisible(true);
  };

  const closeHamburger = () => {
    setIsHamburgerVisible(false);
  };

  const hasTabBar = TAB_ROUTES.includes(pathname);

  return (
    <GuestLayoutContext.Provider
      value={{
        isHamburgerVisible,
        openHamburger,
        closeHamburger,
        isTabBarHidden: isHamburgerVisible ? false : isTabBarHidden,
        showTabBar,
        isScrollTopHidden,
      }}
    >
      <div
        className={cx(styles.Layout, {
          [styles.isSlided]: isHamburgerVisible,
        })}
      >
        <div
          className={cx(styles.ScrollArea, {
            [styles.isSlided]: isHamburgerVisible,
          })}
        >
          {children}
          <If state={hasTabBar}>
            <TabBar />
          </If>
        </div>

        <Hamburger />
        <MenuFilterModal />
      </div>
    </GuestLayoutContext.Provider>
  );
};

export const useGuestLayout = () => {
  return useContext(GuestLayoutContext);
};

GuestLayoutProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GuestLayoutProvider;
