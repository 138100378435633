import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import ICON_FAVORITE_EMPTY from "assets/icons/favorite/FavoriteEmpty.svg";
import ICON_FAVORITE_FULL from "assets/icons/favorite/FavoriteFull.svg";
import ICON_FAVORITE_SMALL_FULL from "assets/icons/favorite/FavoritesSmallFull.svg";
import ICON_FAVORITE_YELLOW_FULL from "assets/icons/favorite/FavoriteYellowHeart.svg";
import ICON_FAVORITE_SMALL_EMPTY from "assets/icons/favorite/FavoritesSmallEmpty.svg";
import FavoriteWhiteHeart from "assets/icons/favorite/FavoriteWhiteHeart.svg";
import HeartRounded from "assets/icons/favorite/heartRounded.svg";
import "./FavoritesIconButton.scss";
export const ENUMS = {
  name: "FavoritesIconButton",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
    TYPE_D: "TYPE_D",
    TYPE_E: "TYPE_E",
    TYPE_F: "TYPE_F",
    TYPE_G: "TYPE_G",
  },
};
const FavoritesIconButton = ({
  id,
  type,
  value,
  onClick,
  hasListViewBorder,
}) => {
  const handleOnClick = (e) => {
    e.stopPropagation();
    onClick(id, value);
  };
  const getSrcOfImg = () => {
    if (
      type === ENUMS.types.TYPE_A ||
      type === ENUMS.types.TYPE_B ||
      type === ENUMS.types.TYPE_D ||
      type === ENUMS.types.TYPE_E
    ) {
      return value ? ICON_FAVORITE_FULL : ICON_FAVORITE_EMPTY;
    } else if (type === ENUMS.types.TYPE_F) {
      return value ? ICON_FAVORITE_YELLOW_FULL : FavoriteWhiteHeart;
    } else if (type === ENUMS.types.TYPE_G) {
      return value ? ICON_FAVORITE_YELLOW_FULL : HeartRounded;
    } else {
      return value ? ICON_FAVORITE_SMALL_FULL : ICON_FAVORITE_SMALL_EMPTY;
    }
  };
  return (
    <button
      onClick={(e) => handleOnClick(e)}
      className={cx(
        "FavoritesIconButton",
        { typeA: type === ENUMS.types.TYPE_A },
        { typeB: type === ENUMS.types.TYPE_B },
        { typeC: type === ENUMS.types.TYPE_C },
        { menuListViewBorder: hasListViewBorder },
        { typeD: type === ENUMS.types.TYPE_D },
        { typeE: type === ENUMS.types.TYPE_E },
        { typeF: type === ENUMS.types.TYPE_F },
        { typeG: type === ENUMS.types.TYPE_G }
      )}
      type="button"
    >
      {" "}
      <img src={getSrcOfImg()} alt="favorite" />{" "}
    </button>
  );
};
FavoritesIconButton.propTypes = {
  /**   * The id of the component   */ id: PropTypes.number.isRequired,
  /**   * The types of the component   */ type: PropTypes.oneOf([
    ENUMS.types.TYPE_A,
    ENUMS.types.TYPE_B,
    ENUMS.types.TYPE_C,
    ENUMS.types.TYPE_D,
    ENUMS.types.TYPE_E,
    ENUMS.types.TYPE_F,
    ENUMS.types.TYPE_G,

  ]),
  /**   * The value of the component   */ value: PropTypes.bool.isRequired,
  /**   * The function called when component clicked   */ onClick:
    PropTypes.func.isRequired,
  /**   * check if the component has list view border   */ hasListViewBorder:
    PropTypes.bool,
};
export default FavoritesIconButton;
