import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { STORE_NAMES } from "utils/constants/redux";
import { findItemByIdForBasket } from "utils/general";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import MenuItemList from "components/menu-item-list/MenuItemList";
import { getBasketModificationsWithDefaultValues } from "utils/helpers";
import { updateBasket, updateFavorites } from "redux/slices/basketStore";

import styles from "./Favorites.module.scss";

const Favorites = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { businessId } = useParams();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);
  const basketOrder = useSelector((state) => state[STORE_NAMES.basket].order);
  const { pathname } = useLocation();
  const guests = basketOrder ? basketOrder.guests : [];
  const guest = guests.find((guest) => guest.person.id === guestId);

  const favoriteItems = useMemo(() => {
    if (!menu || !guest) return [];
    return guest.favoriteItems
      .map((id) => findItemByIdForBasket(id, menu))
      .filter((item) => item && item.isPublished && !item.isArchived);
  }, [menu, guest]);

  const categories = useMemo(
    () => [{ menuItems: favoriteItems }],
    [favoriteItems]
  );

  const handleRemoveFavItem = (menuItem) => {
    dispatch(
      updateFavorites({
        userId: guestId,
        menuItemID: menuItem.id,
      })
    );
  };

  const handleAddToBasket = (menuItem) => {
    const modifications = getBasketModificationsWithDefaultValues(
      menuItem.modifications
    ).filter((modification) => modification.options.length > 0);

    const payload = {
      menuItem: {
        id: menuItem.id,
        modifications: modifications.map((modification) => {
          return {
            id: modification.id,
            options: modification.options.map((option) => {
              return {
                id: option.id,
                count: option.count ?? 1,
              };
            }),
          };
        }),
      },
      count: 1,
      userId: guestId,
    };

    dispatch(updateBasket(payload));
  };

  const handleOnClick = (item) => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItem}/${item.id}?${QUERY_PARAMS.from}=${pathname}`
    );
  };

  const emptyStateComponent = useMemo(
    () => (
      <div className={styles.Container}>
        <h5 className={"Medium"}>{t("basket.emptyFavorite")}</h5>
      </div>
    ),
    [t]
  );

  const goBackHandler = () => {
    const backUrl = businessId
      ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.myProfile}`
      : `${ROUTE_NAME.client}${ROUTE_NAME.myProfile}`;
    navigate(backUrl);
  };

  return (
    <>
      <MenuItemList
        onGoBack={goBackHandler}
        title={t("basket.favorites")}
        categories={categories}
        addToBasket={handleAddToBasket}
        onFavorite={handleRemoveFavItem}
        onClick={handleOnClick}
        emptyStateComponent={emptyStateComponent}
      />
    </>
  );
};
export default Favorites;
