import { TermsAndConditionAz } from "utils/constants/i18n/TermsAndCondition";
import { PrivacyPolicyAz } from "utils/constants/i18n/PrivacyPolicy";

const TRANSLATION = {
  welcome: "Xoş gəldiniz (Azerbaijan)",
  common: {
    settings: "Parametrlər",
    about: "Haqqında",
    home: "Əsas Səhifə",
    logo: "Loqo",
    shape: "Şəkil",
    selected: "Seçilmiş",
    create: "Yarat",
    notFound: "Tapılmadı",
    applied: "Tətbiq olunub",
    yourLikes: "Favoriləriniz",
    details: "Ətraflı",
    published: "Nəşr edilib",
    unPublished: "Nəşr edilməyib",
    general: "Ümumi",
    address: "Ünvan",
    wifi: "Wi-Fi",
    sort: "Sıralama",
    copied: "Kopyalandı",
    contact: "Əlaqə",
    pages: "Səhifələr",
    kcal: "kcal",
    currency: "Valyuta",
    connect: "Qoşul",
    disconnect: "Ayrıl",
    connection: "Qoşulma",
    confirmDisconnect:
      "Adı {{name}} olan şəxsin bağlantısını kəsmək istədiyinizdən əminsiniz?",
    qr: "QR",
    telegram: "Telegram",
    enable: "Aktiv",
    disable: "Deaktiv",
    zoneAndTable: "Zona və Masa",
    connectToWifi: "Wi-Fi ilə qoşuldu",
    connectWifi: "Wi-Fi Bağlan",
    disconnectFromWifi: "Wi-Fi-dən ayrıldı",
    name: "Ad",
    active: "Aktiv",
    inActive: "Aktiv deyil",
    date: {
      date: "Tarix",
      time: "Saat",
      createdAt: "Yaradıldı",
      dateCreated: "Yaradıldı tarixi",
      dateSelection: "Tarix seçimi",
      schedule: "Seçilmiş tarixlərdə fəaliyyət göstərsin",
    },
    time: {
      hour: "saat",
      minute: "dəqiqə",
      second: "saniyə",
      from: "Başlama tarixi",
      to: "Bitmə tarixi",
      since: "Sifariş vaxtı",
      min: "dəq",
      shortVersion: {
        hour: "saat",
        minute: "dəq",
        second: "san",
      },
    },
    days: {
      days: "Günlər",
      monday: "Bazar ertəsi",
      tuesday: "Çərşənbə axşamı",
      wednesday: "Çərşənbə",
      thursday: "Cümə axşamı",
      friday: "Cümə",
      saturday: "Şənbə",
      sunday: "Bazar",
      everyday: "Hər gün",
      everyWeekday: "Hər həftənin iş günü",
      weekend: "Həftəsonu",
    },
  },
  navbarRoutes: {
    statistics: "Statistika",
    salesStatistics: "Satış Statistikası",
    orderStatistics: "Sifariş Statistikası",
    allOrders: "Bütün Sifarişlər",
    menuStatistics: "Menyu Statistikaları",
    allItems: "Bütün Məhsullar",
    feedbacks: "Geri dönüşlər",
    staff: "Əməkdaşlar",
    weeklyStatistics: "Həftəlik Statistikalar",
    menu: "Menyu",
    business: "Biznes",
    users: "İstifadəçilər",
    qrAndTable: "QR və Masa",
    table: "Masa",
    qr: "QR",
    reservation: "Rezervasiya",
    map: "Xəritə",
    orders: "Sifarişlər",
    departments: "Departamentlər",
    superAdmin: "Super İdarəçi",
    inventory: "Anbar",
    funZone: "Əylən",
    home: "Ana səhifə",
    guests: "Qonaqlar",
    payments: "Ödənişlər",
    pageTitles: {
      superAdmin: "SUPER İDARƏÇİ",
      infra: "İnfra",
      guests: "Qonaqlar",
      weeklyStatistics: "HƏFTƏLİK STATİSTİKA",
      salesStatistics: "Satış STATİSTİKASI",
      orderStatistics: "SİFARİŞ STATİSTİKASI",
      menuStatistics: "Menyu STATİSTİKALARI",
      allOrders: "BÜTÜN SİFARİŞLƏR",
      allItems: "BÜTÜN MƏHSULLAR",
      feedbacks: "GERİ DÖNÜŞLƏR",
      category: "KATEQORİYA",
      settings: "Parametrlər",
      business: "BİZNES",
      users: "İSTİFADƏÇİLƏR",
      zones: "Zonalar",
      tables: "Masalar",
      qrList: "QR SİYAHISI",
      integrations: "İnteqrasiyalar",
      payments: "ÖDƏNİŞLƏR",
      telegramIntegration: "Telegram - inteqrasiya",
    },
  },
  emptyTable: {
    emptyTableDescription: "Hələ heç bir zona yoxdur.",
    emptyZoneDescription: "Bu zonada heç bir masa yoxdur.",
    emptyQR: "Hələ heç bir QR kodunuz yoxdur.",
    emptyZoneTitle: "Xəritədə heç bir masa yoxdur.",
  },
  language: {
    language: "Dil",
    english: "İngilis",
    russian: "Rus",
    azerbaijani: "Azərbaycan",
    german: "Alman",
  },
  buttons: {
    addItem: "Məhsul əlavə et",
    addQR: "QR əlavə et",
    back: "Geri",
    createQR: "QR yarat",
    createZone: "Zona yarat",
    createOrder: "Sifariş yarat",
    edit: "Redaktə et",
    delete: "Sil",
    confirm: "Təsdiq et",
    finish: "Bitir",
    discard: "İmtina et",
    change: "Dəyişdir",
    deny: "Rədd et",
    save: "Yadda saxla",
    close: "Bağla",
    saveChanges: "Dəyişiklikləri saxla",
    all: "Hamısı",
    selectAll: "Hamısını seç",
    seeAll: "Hamısı",
    seeLess: "Az göstər",
    add: "Əlavə et",
    addNew: "Yeni əlavə et",
    reset: "Təmizlə",
    resend: "Təkrar göndər",
    clearFilters: "Filterləri təmizlə",
    pay: "Ödə",
    clear: "Hamısını təmizlə",
    apply: "Tətbiq et",
    defaultLanguage: "Əsas Dil",
    defaultCurrency: "Valyuta",
    availableLanguages: "Mövcud Dillər",
    currency: "Sabit Valyuta",
    link: "Link",
    links: "Linklər",
    unlink: "Bağlantını kəs",
    addBasket: "Səbətə əlavə et",
    addToOrder: "Sifarişə əlavə et",
    clickToAdd: "Əlavə etmək üçün klikləyin",
    clickToAddImage: "Şəkil əlavə etmək üçün klikləyin",
    clickToUpload: "Yükləmək üçün klikləyin",
    logOut: "Çıxış",
    login: "Giriş",
    lockScreen: "Kilid",
    goToDashboard: "İdarə panelinə keç",
    rememberMe: "Məni yadda saxla",
    forgotPassword: "Şifrənizi unutmusunuz?",
    payWith: "İlə ödə",
    seeMenu: "Menyunu gör",
    addOrder: "Yeni sifariş",
    readMore: "Daha çox oxu",
    serve: "Xidmət et",
    confirmOrder: "Sifarişi təsdiqlə",
    backToMenu: "Menyuya geri dön",
    send: "Göndər",
    tryAgain: "Təkrar cəhd et",
    update: "Yenilə",
    continue: "Davam et",
    verify: "Yoxla",
    applyDiscount: "Endirim tətbiq et",
    discountProducts: "Endirimli məhsullar",
    print: "Çap et",
    closeOrder: "Sifarişi bitir",
    printBill: "Hesabı çap et",
    fullTables: "Dolu Masalar",
    editForModal: "{{title}} redaktə edin",
    addForModal: "{{title}} əlavə edin",
    successfullySent: "Uğurla Göndərildi",
    sent: "Göndərildi",
    reserve: "Rezerv etmək",
    skip: "Keç",
    source: "Mənbə",
    target: "Hədəf",
    editZone: "Zonanı Redaktə Et",
    contactUs: "Bizimlə əlaqə saxlayın",
  },
  inputs: {
    name: "Ad",
    username: "İstifadəçi adı",
    description: "Açıqlama",
    price: "Qiymət",
    discount: "Endirim",
    role: "Rol",
    phoneNumber: "Telefon nömrəsi",
    pinCode: "PIN Kod",
    pinCodeConfirm: "PIN Kodunu Təsdiqlə",
    firstName: "Ad",
    lastName: "Soyad",
    email: "Email",
    password: "Şifrə",
    confirmPassword: "Şifrəni təsdiq edin",
    newPassword: "Yeni şifrə",
    oldPassword: "Köhnə şifrə",
    businessName: "Biznesin adı",
    city: "Şəhər",
    zipCode: "Poçt indeksi",
    street: "Küçə",
    map: "Google maps linki",
    serviceFee: "Xidmət haqqı",
    search: "Axtarış",
    enterPromoCode: "Promo kodunu daxil edin",
    link: "Bağlantı əlavə et",
    instagram: "Instagram linki",
    phone: "Telefon əlavə et",
    contactPhone: "Telefon nömrəsi",
    whatsapp: "WhatsApp nömrəsi",
    mail: "Email əlavə et",
    contactMail: "Email ünvanı",
    tableName: "Masa Adı",
    usernameExample: "@nümunə",
    chooseCurrent: "Cariyi seçin",
    seats: "Oturacaq Sayı",
    minDeposit: "Min. Depozit",
    qrName: "QR Adı",
    wifiName: "Wi-Fi adı",
    sell: "Satış qiyməti",
    cost: "Maya dəyəri",
    calories: "Kalori",
    time: "Vaxt",
    amount: "Miqdar",
    unit: "Vahid",
    addComment: "Şərh əlavə et",
    warningAmount: "Xəbərdarlıq miqdarı",
    number: "Miqdar",
    notFixed: "Sabit deyil",
    fixed: "Sabit",
    maxNumber: "Maksimum say",
    deposit: "Depozit",
    yourName: "Sizin adınız",
    contact: "Əlaqə",
    reserveeName: "Rezerv edən şəxsin adı",
    numberOfGuest: "Qonaq sayı",
    date: "Tarix",
    duration: "Müddət",
    comment: "Şərh...",
    commentText: "Şərh",
    fullName: "Tam Ad",
    dateOfBirth: "Doğum Tarixi",
  },
  image: {
    images: "Şəkillər",
    selectCover: "əsas şəkli təyin etmək üçün şəkli klikləyin",
    background: "Arxa plan şəkli",
    other: "Digər şəkillər",
    addPhoto: "Şəkil əlavə et",
    addImage: "Şəkil əlavə et",
    formats: "SVG, PNG, JPEG",
  },
  modification: {
    modificationName: "Modifikasiyanın Adı",
    modification: "Modifikasiya",
    modifications: "Modifikasiyalar",
    noModifications: "Hələ modifikasiya əlavə edilməyib.",
    noModificationOption: "Hələ seçim əlavə edilməyib.",
    addModification: "Dəyişiklik əlavə et",
    editModification: "Dəyişikliyi redaktə et",
    selection: {
      selection: "Seçim",
      types: "Seçim Növü",
      singleSelection: "Tək",
      singleSelectionDescription: "yalnız bir seçim edilə bilər",
      multiSelection: "Çox",
      multiSelectionDescription: "bir neçə seçim edilə bilər",
      option: {
        options: "Seçimlər",
        onlyOnce: "Yalnız bir dəfə",
        manyTimes: "Bir çox dəfə",
        default: "Sabit seçim",
        addLanguage: "Dil əlavə et",
      },
    },
  },
  user: {
    staff: "Əməkdaşlar",
    listOfEmployees: "{{count}} əməkdaş",
    employee: "İşçi",
    addEmployee: "Əməkdaş əlavə et",
    editEmployee: "Əməkdaşı redaktə et",
    sendAllDetails: "Yeni şifrə yaradın və istifadəçiyə göndərin",
    userDeleteError: "Siz daxil olduğunuz hesabı silə bilməzsiniz.",
    generatePassword: "Yeni bir parol yaradın və istifadəçiyə göndərin",
    changeYourPassword: "Şifrənizi dəyişin",
  },
  table: {
    connected: "QR ilə əlaqələndirildi",
    tablesLowercase: "masalar",
    tables: "Masalar",
    table: "Masa",
    addTable: "Masa əlavə et",
    editTable: "Masa redaktə et",
    selectTable: "Masa seçin",
    busy: "masalar doludur",
    available: "masalar müsaitdir",
    full: "dolu",
    noTablesVisible: "Hal-hazırda xəritədə heç bir masa görünmür.",
    map: "Xəritə",
    current: "cari",
  },
  zone: {
    zone: "Zona",
    tables: "Masalar",
    zones: "Zonalar",
    name: "Zona adı",
    allZones: "Bütün zonalar",
  },
  map: {
    removeFromMap: "Xəritədən sil",
    shapes: {
      rectangle: "Düzbucaqlı",
      circle: "Dairə",
    },
    createTable: "Masa Yarat",
    addToMap: "Xəritəyə əlavə et",
    windowAndDoor: "Pəncərə və Qapı",
    designElements: "Dizayn Elementləri",
    decoration: "Dekorasiya",
    reset: "Sıfırla",
    width: "En",
    height: "Hündürlük",
  },
  sort: {
    az: "A-Z",
    cheapToExpensive: "Ucuzdan Bahaya",
    expensiveToCheap: "Bahadan Ucuza",
    new: "Yeni",
  },
  qr: {
    qr: "QR",
    qrCode: "QR kod",
    name: "QR Adı",
    list: "QR siyahısı",
    id: "QR ID",
    type: "QR Kod Növü",
    status: "Status",
    unlinked: "Bağlantısız",
    linked: "Bağlantılı",
    qrIsNotAdded: "QR əlavə edilməyib",
    connected: "Bağlantılı",
    notConnected: "Bağlantısız",
    scanToConnect: "və ya qoşulmaq üçün skan edin",
    QRLinkCopied: "QR linki kopyalandı",
    qrScan: "Bu QR kodunu skan et",
    scanCount: "Skan Sayı",
    qrScanDescription:
      "* İstifadəçinin mobil telefonundan skan edin və Telegram tətbiqinin quraşdırıldığından əmin olun",
    types: {
      qrType: "QR Növü",
      wifi: "Wi-Fi",
      menu: "Menyu",
    },
    downloadAllQr: "Bütün QR-ları yüklə",
  },
  modal: {
    yesIConfirmMyOrderAndTakeResponsibilityToPay:
      "Bəli, mən sifarişimi təsdiq edirəm və ödəmək üçün məsuliyyəti qəbul edirəm.",
    areYouSureYouWantToPlaceThisOrder:
      "Bu sifarişi vermək istədiyinizə əminsinizmi?",
    deleteModalTitle: "Silməyə əminsiniz?",
    deleteModalDescription: "Bu əməliyyat geri qaytarıla bilməz",
    warningModalTitleFinishOrder:
      "Bu sifarişi bitirmək istədiyinizə əminsiniz?",
    accessDenied: "Giriş rədd edildi",
    permissionRequired: "İcazə tələb olunur",
    confirmModalTitleLogOut: "Çıxmaq istədiyinizə əminsiniz?",
    confirmModalDescriptionLogOut:
      "İstənilən vaxt yenidən daxil ola bilərsiniz",
    warningModalTitleChangeZone: "Bu zonanı dəyişmək istədiyinizə əminsiniz?",
    warningModalTitleLogout: "Çıxmaq istədiyinizə əminsiniz?",
    warningModalTitleNewOrder:
      "Siz {{zone}}/{{table}} üzrə yeni sifariş yaradacaqsınız. Əminsiniz?",
    warningModalDescriptionNewOrder:
      "Yeni sifariş yaratmaq üçün bəli düyməsinə basın",
    deleteMenuItemTitle: "Bu məhsulları silmək istədiyinizə əminsiniz?",
    warningModalTitleUnsavedChanges:
      "Dəyişiklikləri yadda saxlamadan çıxmaq istədiyinizə əminsiniz?",
    warningModalDescription: "Sizin dəyişiklikləriniz yadda saxlanılmayacaq",
    yes: "Bəli",
    no: "Xeyr",
    close: "Bağla",
    apply: "Tətbiq et",
    id: "ID",
    cannotBeRemoved: "Təsdiqlənmiş məhsullar silinə bilməz",
    unConfirmedCannotBeRemoved: "Təsdiqlənməmiş məhsullar ödənilə bilməz",
    payMenuItemTitle: "Bu seçimləri ödəmək istədiyinizdən əminsiniz?",
    guestProfile: "Qonaq Profili",
    totalVisits: "Cəmi Ziyarətlər",
    averageAmount: "Ortalama Məbləğ",
    topOrder: "Ən çox sifariş olunan {{count}} məhsul",
    createdBy: "Yaradan",
    paymentMethod: "Ödəniş Metodu",
    orderId: "Sifariş ID",
    transactionId: "Transaksiya ID",
    visitHistory: "Ziyarət Tarixi",
  },
  menu: {
    preview: "Önizləmə Menyusu",
    search: "Sevdiyiniz yeməyi axtarın...",
    translate: {
      ai: "Sehirli tərcümə",
      translating: "Tərcümə edilir...",
      aiAutoTranslate: "AI Avtomatik Tərcümə",
      languageSelection: "Dil seçimi",
      successfulTranslate: "Uğurla Tərcümə Edildi",
      waitingTranslate:
        "Aİ menyunu tərcümə etmək üçün bir neçə dəqiqə vaxt alacaq. Bu müddət ərzində menyu məhsullarını redaktə etmək mümkün deyil.",
      magicTranslate: "Tərcümə et",
      itemErrorMessage:
        "Məhsul bu dildə mövcud deyil. Tərcümə etmək üçün obyekt mənbə dilində əlavə olunmalıdır.",
      categoryErrorMessage:
        "Bu kateqoriya mənbə dilində mövcud deyil. Tərcümə etmək üçün kateqoriya mənbə dilində əlavə olunmalıdır.",
    },
    tag: {
      tag: "Etiket",
      tags: "Etiketlər",
      name: "Etiket adı",
    },
    category: {
      category: "Kateqoriya",
      categories: "Kateqoriyalar",
      add: "Kateqoriya əlavə et",
      all: "Bütün Kateqoriyalar",
      name: "Kateqoriya adı",
      mealCategories: "Yemək Kateqoriyaları",
      emptyGuestMenuTitle: "Menyu Boşdur",
      emptyGuestMenuDescription:
        "Görünür ki, biznes hələ menyuya heç nə əlavə etməyib",
      emptyAdminCategoryDescription:
        "Hələ ki, sizin heç bir kateqoriyanız yoxdur.",
    },
    item: {
      items: "Məhsullar",
      item: "Məhsul",
      all: "Bütün Məhsullar",
      itemLowerCase: "məhsul",
      itemsLowerCase: "məhsullar",
      selected: "Seçilmiş maddələr",
      deleteItem: "Məhsulu sil",
      duplicateItem: "Məhsulu kopyala",
      duplicate: "Dublikat",
      scheduleDiscount: "Endirim vaxtını təyin et",
      emptyAdminMenuItemDescription:
        "Hələ ki, sizin heç bir məhsulunuz yoxdur.",
      itemDeleteError:
        "Bu məhsulu silə bilməzsiniz, çünki o aktiv bir sifarişte mövcuddur.",
    },
    view: {
      selection: "Menyu Görüntüsü",
      scroll: "Sürüşdür",
      withImage: "Şəkil ilə",
      withoutImage: "Şəkilsiz",
      list: "Siyahı",
      threeD: "3D",
      poker: "Poker",
      grid: "Qrej",
    },
    filter: {
      filters: "Filterlər",
      priceRange: "Qiymət Aralığı",
      noResult: "Heç bir nəticə uyğun deyil",
      noResultDesc: "Aktiv filterlər bütün menyu məhsullarını gizlədir.",
    },
    settings: {
      color: "Rəng",
      radius: "Kənar Radiusu",
      roundness: "Dairəvilik",
      currency: "Valyuta",
      colors: {
        primary: "Əsas rənglər",
        red: "Qırmızı",
        gold: "Qızıl",
        purple: "Bənövşəyi",
        blue: "Mavi",
      },
      curves: {
        elementCurves: "Element Curves",
        none: "Yoxdur",
        small: "Kiçik",
        medium: "Orta",
        large: "Böyük",
      },
      changeProperties: "Xüsusiyyətləri dəyişdir",
      details: "Menyunun Ətraflı Məlumatları",
    },
  },
  menuV2: {
    selectLanguage: "Dil seçin",
  },
  business: {
    businesses: "Bizneslər",
    list: "Bizneslərin siyahısı",
    staffList: "{{count}} əməkdaş",
    selectTitle: "Biznes seçin",
    selectSubTitle:
      "Zəhmət olmasa daxil olmaq istədiyiniz biznesi seçin və sonra davam edin",
    contact: "Əlaqə",
    contactWith: "Əlaqə",
    workingHours: "İş saatları",
    needToKnow: "Bilmək lazımdır",
    addNew: "Biznes əlavə et",
    edit: "Biznesi redaktə et",
    plan: "Plan",
    selectPlan: "Planı seçin",
    creationDate: "Yaradılma tarixi",
    status: "Status",
    verified: "Təsdiqlənib",
    unverified: "Təsdiqlənməyib",
    businessStatus: "Biznesin vəziyyəti",
    expirationDate: "Bitmə tarixi",
    country: "Ölkə",
    printers: "Printerlər",
    printer: "Printer",
    guestBusinessBlockTitle: "Bu biznes aktiv deyil.",
    guestBusinessBlockSubTitle: "Bağışlayın, bu biznes artıq mövcud deyil.",
    settings: {
      goLive: "Canlı Yayım",
      chat: "Söhbət",
      realTimeOrder: "Canlı",
      menuItemCalorie: "Kalori",
      menuItemTime: "Vaxt",
      menuItemAmount: "Miqdar",
      reservation: "Rezervasiya",
      reservationMap: "Rezervasiyada xəritəni göstər",
      chatSound: "Söhbət",
      orderSound: "Sifariş",
      askForBillSound: "Hesab İstəmə",
      callWaiterSound: "Ofisiant Çağırma",
      isAskForBillEnabled: "Hesab İstəmə",
      isCallWaiterEnabled: "Ofisiant Çağırma",
      menu: "Menyu",
      sound: "Səs",
      general: "Ümumi",
      order: "Sifariş",
      action: "Fəaliyyətlər",
      reservationVRLink: "Virtual link əlavə et",
      reservationVREnabled: "Virtual baxış",
      reservationMapEnabled: "Xəritə görünüşü",
      isOnlinePaymentEnabled: "Ödəniş",
      showInBusinessCatalogue: "Biznes Kataloqunda Göstər",
      permission: "İcazələr",
      declineOrderItemsEnabled: "Sifariş məhsullarını rədd et",
      deleteOrderEnabled: "Sifarişi sil",
      deleteMenuItemsEnabled: "Menyu məhsullarını sil",
      editMenuEnabled: "Menyuya düzəliş et",
      virtualTour: "Virtual Tur",
      vrLink: "Virtual link əlavə et",
      vrLinkEnabled: "Virtual baxış",
    },
  },
  orderItem: {
    denied: "İmtina edildi",
    deleted: "Silindi",
    empty: "Boş",
  },
  basket: {
    basket: "Səbət",
    favorites: "Favorilər",
    basketAndFavorites: "Səbət & Favorilər",
    addMessage: "Restoran üçün mesaj əlavə edin",
    messagePlaceholder: "Xüsusi tələb, allergiya, qidalanma məhdudiyyəti?",
    itemAdded: "{{count}} məhsul səbətə əlavə edildi",
    emptyFavorite: "Sizin hələ favoritləriniz yoxdur",
    added: "Əlavə edildi",
    order: {
      info: "Sifariş Məlumatı",
      subtotal: "Yekun",
      total: "Ümumi",
      tax: "Vergi",
      promoCode: "Promo kodu",
      totalCost: "Ümumi məbləğ",
      orders: "Sifarişlər",
      myOrders: "Sifarişlərim",
      ordersLowerCase: "sifarişlər",
      orderLowerCase: "sifariş",
      order: "Sifariş",
      bill: "Hesab",
      noOrder: "Mövcud sifariş yoxdur",
      deletedOrderTitle: "Sifarişiniz silindi",
      deletedOrderDescription:
        "Üzr istəyirik, sifarişiniz admin/ofisiant tərəfindən silinib",
      notifications: "Bildirişlər",
      updatesToSave: "Saxlamaq üçün yeniliklər",
      newOrder: "Yeni Sifariş",
      deleteOrder: "Sifarişi sil",
      finishOrder: "Sifarişi bitir",
      done: "Həyata keçirilib",
      preparing: "Hazırlanır",
      numberOfGuests: "Qonaqların sayı",
      fullNessOfTable: "Masa doluluğu",
      acceptAll: "Hamısını Qəbul Et",
    },
    emptyBasketTitle: "Səbətiniz boşdur",
    emptyBasketDescription: "Görünür ki, səbətinizə heçnə əlavə etməmisiniz",
  },
  orders: {
    orders: "Sifarişlər",
    order: "Sifariş",
    guests: "Qonaqlar",
    guest: "Qonaq",
  },
  funZone: {
    selectGame: "Oyunu seç",
    playGame: "Oyuna başla",
    who: "Kim",
    paysTheBill: "Hesabı kim ödəyəcək?",
    mindGame: "Yaddaş",
    canYouRemember: "Xatırlaya bilərsən?",
    tower: "Qala",
    buildYourHighestTower: "Ən hündür qalanı tik",
    ticTackToe: {
      title: "X/O",
      player: "Oyuncu",
      turnFor: "{{player}} üçün növbə",
      win: "Qazandı",
      outsmartYourOpponent: "Rəqibinə qalib gəl!",
      draw: "Bərabərlik",
      points: "Bal",
    },

    wheelOfFortune: {
      spinnerGame: "Spinner Oyunu",
      who: "Kim?",
      whoWillPayTheBill: "Kim hesabı ödəyəcək?",
      whoWillGoToTheMarket: "Kim marketə gedəcək?",
      whoWillBuyCigarettes: "Kim siqaret alacaq?",
      whoWillHaveAnotherShot: "Kim bir dəfə daha içəcək?",
      whoWillGetDessert: "Kim desert alacaq?",
      whoWillChooseTheMusic: "Kim musiqini seçəcək?",
      whoWillSingKaraoke: "Kim oxuyacaq?",
      start: "Başla",
      spinning: "Dönür...",
      editToPlay: "Oynamaq üçün düzəliş et",
      addNew: "Yeni seçim əlavə et",
      saveAndContinue: "Yadda saxla və davam et",
      typeHere: "Buraya yazın",
      result: "Nəticə",
      youAreTheChosenOne: "Sən seçilmiş birisən",
    },
    memoryCardGame: {
      mindGame: "Yaddaş Oyunu",
      restart: "Yenidən başla",
      winMessage: "Qazandın",
      loseMessage: "Məğlub oldun",
      secondsLeft: "Qalan saniyə",
      triesLeft: "Qalan cəhdlər",
      startNewGame: "Yeni oyuna başla",
    },
    towerGame: {
      blocks: "BLOKLAR",
      clickToPlay: "OYUN ÜÇÜN TIKLAYIN",
      clickToRestart: "Yenidən başlatmaq üçün klikləyin",
    },
  },
  guests: {
    guest: "Qonaq",
    guests: "Qonaqlar",
    registeredGuest: "Qeydiyyatlı",
    unregisteredGuest: "Qeydiyyatsız",
    listOfGuests: "{{count}} qonaq",
    allBusinesses: "Bütün Bizneslər",
  },
  dashboard: {
    dashboard: "İdarə Paneli",
    sales: "Satışlar",
    startTalk: "Söhbəti Başlat",
    salesAnalytics: "Ümumi Satış / Saat",
    weekStatistics: "Həftə Statistikası",
    statistics: "Statistika",
    totalSales: "Ümumi Satış",
    paymentMethodAnalysis: "Ödəniş Üsulunun Təhlili",
    byNumberOfOrders: "Sifariş sayına görə",
    byTheAmountOfSales: "Satış miqdarına görə",
    byTheNumberOfRatings: "Reytinq sayına görə",
    bestselling: "Ən çox satılan",
    worstSelling: "Ən az satılan",
    selectToPay: "Seçmək üçün klikləyin",
    processing: "İşlənir",
    billReady: "Hesab hazırdır",
    unSelect: "Seçimi ləğv et",
    denied: "İmtina",
    deleted: "Silindi",
    showMore: "Daha çox göstər",
    showLess: "Daha az göstər",
    bestSellingCategory: "Ən çox satılan kateqoriya",
    topPerformer: "Ən yaxşı icra edən",
    lowPerformer: "Ən pis icra edən",
    noResultForThisPeriodMsg: "Bu dövr üçün heç bir nəticə yoxdur",
    all: "Hamısı",
    yourTable: "Sizin Masanız",
    totalGuests: "Ümumi Qonaqlar",
    totalItems: "Ümumi Məhsullar",
    weeklySaleStatistics: "Həftəlik Satış Statistikası",
    weeklyOrderStatistics: "Həftəlik Sifariş Statistikası",
    paid: "Ödəndi",
    paymentInProgress: "Ödəniş davam edir",
    inProgress: "Davam edir",
    legends: {
      sale: "Satış",
      revenue: "Gəlir",
      orders: "Sifariş sayı",
      orderItems: "Məhsul sayı",
      totalSale: "Ümumi Satış",
      starCount: "Ulduz sayı",
    },
    guest: {
      guests: "Qonaq",
      guest: "Qonaq",
      me: "Mən",
      review: {
        meal: "Qida",
        service: "Xidmət",
        review: "Rəy",
        sent: "Göndərildi",
        feedBack: " Bizi dəyərləndir",
      },
    },
    staffStatistics: {
      sales: "Satışlar",
      orders: "Sifarişlər",
      rating: "Reytinq",
    },
    orderStatistics: {
      totalSale: "Ümumi Satış",
      revenue: "Gəlir",
      averageBill: "Orta Hesab",
      orderCount: "Sifariş sayı",
      guestsServed: "Xidmət olunmuş qonaqlar",
      averageTime: "Ortalama vaxt",
      from: "dən",
      orders: "Sifarişlər",
      sale: "Satış",
      in: "Daxil",
      out: "Çıxar",
    },
    feedbackStatistic: {
      service: "Xidmət Geri Dönüşləri",
      meal: "Qida Geri Dönüşləri",
    },
    table: {
      adminOrderHistoryTable: {
        staff: "Əməkdaş",
        dates: "Tarixlər",
        item: "Məhsul",
        subTotal: "Alt Yekun",
        discount: "Endirim",
        total: "Ümumi",
        zone: "Zona",
        table: "Masa",
        service: "Xidmət",
        fee: "Haqqı",
        orderDetails: "Sifariş Detalları",
        receipt: "Qəbz",
      },
      menuStatisticsTable: {
        menuItem: "Məhsul",
        category: "Kateqoriya",
        cost: "Maya dəyəri",
        revenue: "Gəlir",
        total: "Ümumi",
        order: "Sifariş",
        count: "Sayı",
        price: "Qiymət",
        item: "Məhsul",
      },
      businessGuestsTable: {
        name: "Ad",
        gender: "Cinsiyyət",
        dateOfBirth: "Doğum tarixi",
        numberOfVisit: "Ziyarət sayı",
        guestCount: "Qonaq sayı",
        pageSize: "Səhifə ölçüsü",
      },
    },
    pagination: {
      prev: "Əvvəlki",
      next: "Sonrakı",
    },
    prevNext: {
      previous: "Əvvəlki",
      next: "Sonrakı",
    },
    placeholder: {
      zone: "Zona",
      orderId: "Sifariş ID",
      staff: "Əməkdaş",
      itemName: "Maddə adı",
      categories: "Kateqoriyalar",
    },
  },
  login: {
    or: "və ya",
    facebook: "Facebook ilə",
    google: "Google ilə",
    pin: "pin",
    alreadyHaveAnAccount: "Artıq hesabınız var?",
    signIn: {
      title: "Giriş",
      subTitle: "Hesabınız yoxdur?",
      details: "Giriş məlumatları",
      method: "Daxilolma metodu",
    },
    signUp: {
      termsErrorMessage:
        "*Davam etmək üçün şərtləri və qaydaları qəbul etməlisiniz.",
      title: "Qeydiyyat",
      subTitleForUserExist:
        "Sizin artıq bir hesabınız var. Xahiş edirəm mövcud şifrənizi daxil edin.",
      subTitleForUserNotExist:
        "Zəhmət olmasa göndərilən şifrəni daxil edin: {{emailOrPhone}}",
      alreadyHaveAccount: "Artıq hesabınız var?",
      passwordSentTo: "Şifrə göndərildi",
      setPinSubTitle: "Ən sadə giriş üçün bir PIN Kodu qoyun",
      agree: "Bunları qəbul edirəm:",
      terms: "İstifadəçi razılığı",
      privacy: "Gizlilik siyasəti",
      enterPassword: "Şifrəni daxil edin",
      setPinSubtitle: "Ən sadə giriş üçün bir PIN Kodu qoyun",
    },
    forgotPassword: {
      title: "Şifrənizi unutmusunuz?",
      subTitle:
        "Zəhmət olmasa {{method}} daxil edin və şifrənizi yenidən göndərəcəyik.",
      sendPassword: "Şifrəni göndər",
      email: "e-poçt",
      phoneNumber: "telefon nömrəsi",
    },
    resetPassword: {
      title: "Şifrəni dəyişdir",
      subTitle: "Xoş gəldiniz, siz məni özleyirdiniz!",
    },
    resendPassword: {
      title: "Şifrəni yenidən göndər",
      subTitleForUserExist: "Mövcud şifrənizi unutdunuz?",
      subTitleForUserNotExist: "Şifrəni almadınız?",
      buttonTextForUserExist: "Yeni şifrə göndər",
      buttonTextForUserNotExist: "Yenidən göndər",
    },
  },
  payment: {
    receipt: "Qəbz",
    payedBy: "Ödənişi edən",
    onlinePayment: "Onlayn Ödəniş",
    paymentMessage: "{{card}} kart ilə ödəniş uğurla tamamlandı",
    paymentFailMessage: "{{card}} kartı ilə ödəniş uğursuz oldu",
    payment: "Ödəniş",
    method: "Ödəniş metodu",
    pageTitle: "Necə ödəmək istəyirsiniz?",
    pageSubtitle: "Ödəniş üsulunu seçin",
    cash: "Nağd",
    POSTerminal: "POS Terminal",
    ready: "Bir dəqiqədə hazır!",
    confirmPOSTerminal:
      "Təsdiq edilmiş sorğu. POS terminalı birazdan sizi garson gətirəcək",
    confirmCash:
      "Təsdiq edilmiş sorğu. Hesabınız birazdan sizi garson gətirəcək",
    totalCostFor: "Ümumi məbləğ",
    printsAndBill: "Bastırmaq və hesab",
    paymentConfirmedTitle:
      "Sifarişiniz başa çatdı. Bizi seçdiyiniz üçün təşəkkürlər.",
    paymentConfirmedDescription:
      "Sizi qonaq etmək bizim üçün xoş idi! Gələn dəfə görüşənədək 👋 Gününüz xoş keçsin 😍",
    businessPaymentsTitle: {
      orderId: "Order ID",
      paidPrice: "Ödəniş məbləği",
      currency: "Valyuta",
      paymentStatus: "Ödəniş Statusu",
      lastFourDigits: "Son 4 Rəqəm",
      cardClassification: "Kartın təsnifatı",
      cardHolderName: "Kart sahibi",
    },
  },
  waiter: {
    waiter: "Ofisiant",
    waiterLowercase: "ober",
    call: "Ofisiantı çağır",
    bill: "Hesabı istə",
    quickActions: "Tezliklər",
    actions: "Fəallıqlar",
  },
  mascot: {
    about: "{{businessName}} Haqqında",
    dashboard: {
      support: "Dəstək və qısa yol",
      eatIn: "İçmək",
      eatInHall: "İçmək zalı",
      reserveTable: "Masanı rezerv edin",
      letUs: "Bizə izin verin .. sizin üçün",
      learnMore: "Daha çox öyrənin ",
      fixProblemText: "Tətbiq ilə hər hansı bir probleminiz var?",
      fixProblemButton: "Düzəltmək üçün Bura basın",
    },
  },
  errorMessages: {
    selectTableToCreateOrder: "Sifariş yaratmaq üçün masa seçin",
    selectWaiterToCreateOrder: "Sifariş yaratmaq üçün ofisiant seçin",
    lastLanguage: "Son dilinizi silə bilməzsiniz",
    orderAssignee: "Bir təyinat seçməlisiniz",
    required: {
      password: "Şifrə tələb olunur",
      confirmPassword: "Şifrəni təsdiq edin tələb olunur",
      customMessage: "{{name}} tələb olunur",
    },
    image: "Şəkil yükləmə uğursuz oldu",
    addUnit: "Vahid əlavə edin",
    confirmOrders:
      "Tamamlanmamış işləriniz var. Davam etməzdən əvvəl onları yadda saxlayın.",
    input: {
      maxCharacter: "*Xahiş edirəm {{max}} simvoldan çox daxil etməyin",
      minCharacter: "*Xahiş edirəm ən az {{min}} simvol daxil edin",
      maxNumber: "*Xahiş edirəm {{max}} -dən kiçik bir nömrə daxil edin",
      minNumber: "*Xahiş edirəm 0-dan böyük bir nömrə daxil edin",
      invalid: "*Yanlış {{name}}",
      required: "*{{name}} tələb olunur",
      generalRequired: "*Bu sahə tələb olunur",
      qrAndTable: {
        maxSeat: "Oturacaq sayı tam ədəd olmalıdır",
      },
      menu: {
        fixedDiscount: "*Endirim satış qiymətindən böyük olmamalıdır",
        unFixedDiscount: "*Endirim 100% -dən böyük olmamalıdır",
        requiredUnit: "*Verilmiş məbləğ üçün bir vahid seçin",
      },
      menuSettings: {
        requiredDefaultLanguage: "*Xahiş edirəm standart dil seçin",
        requiredAvailableLanguages: "*Ən az bir dil seçin",
      },
      pinCode: {
        maxDigits: "*Pin kodu 6 rəqəmindən az olmalıdır",
        alreadyExists: "*Bu pin kodu artıq mövcuddur",
        notNumeric: "*Pin kodu yalnız rəqəmlərdən ibarət olmalıdır",
        skipOrSetNow: "*İndi bir pin kodu qoyun və ya bu addımı keçin",
        notMatch: "*Pin kodları uyğun gəlmir",
        incorrect: "*PIN kod yanlışdır",
        TryAgainLater:
          "Siz artıq limiti keçmisiniz, zəhmət olmasa sonra yenidən cəhd edin.",
      },
      email: {
        alreadyExists: "*Bu email artıq mövcuddur",
        incorrect: "*Xahiş edirəm düzgün bir email daxil edin",
        notExists: "*Bu email mövcud deyil",
        TryAgainLater:
          "*Siz artıq limiti keçmisiniz, zəhmət olmasa sonra yenidən cəhd edin.",
      },
      password: {
        incorrect: "*Yanlış şifrə",
        notNumeric: "*Şifrə yalnız rəqəmlərdən ibarət olmalıdır",
        incorrectLength: "*Şifrə 6 rəqəm olmalıdır",
      },
      phoneNumber: {
        incorrectLength: "*Telefon nömrəsi 12 rəqəm olmalıdır",
        notNumeric: "*Telefon nömrəsi yalnız rəqəmlərdən ibarət olmalıdır",
        alreadyExists: "*Bu telefon nömrəsi artıq mövcuddur",
        incorrect: "*Xahiş edirəm doğru bir telefon nömrəsi daxil edin",
        notExists: "*Bu telefon nömrəsi mövcud deyil",
        TryAgainLater:
          "*Siz artıq limiti keçmisiniz, zəhmət olmasa sonra yenidən cəhd edin.",
      },
      reservation: {
        maxGuestCount: "Qonaq sayı tam ədəd olmalıdır",
      },

      business: {
        serviceFeeMax: "*Xidmət haqqı 100%-dən az olmalıdır",
        serviceFeeMin: "*Xidmət haqqı 0%-dən çox olmalıdır",
        url: "*Xahiş edirəm düzgün bir URL daxil edin",
      },
      contact: {
        instagram: "*Xahiş edirəm düzgün bir Instagram ünvanı daxil edin",
        // phone: "*Please enter a valid phone number",
        // whatsapp: "*Please enter a valid whatsapp number",
        // gmail: "*Please enter a valid gmail address",
      },
      otpCode: {
        OtpIsWrong: "*Otp səhvdir",
        OtpIsNotVerified: "Otp təsdiq edilməyib",
        sendOtp: "Kod göndərildi:",
      },
      oldPassword: {
        OldPasswordIsWrong: "*Cari parol yanlışdır",
      },
    },
  },
  toastMessages: {
    success: {
      login: "Uğurlu giriş!",
      updateBusinessProfile: "Biznes profili uğurla yeniləndi",
      updateTag: "Etiket uğurla yeniləndi",
      createTag: "Etiket uğurla yaradıldı",
      deleteTag: "Etiket uğurla silindi",
      createReservation: "Rezervasiya uğurla yaradıldı",
      deleteReservation: "Rezervasiya uğurla silindi",
      updateReservation: "Rezervasiya uğurla yeniləndi",
      updateCategory: "Kateqoriya uğurla yeniləndi",
      createCategory: "Kateqoriya uğurla yaradıldı",
      deleteCategory: "Kateqoriya uğurla silindi",
      updateMenuItem: "Məhsul uğurla yeniləndi",
      updateMenu: "Menyu uğurla yeniləndi",
      createMenuItem: "Məhsul uğurla yaradıldı",
      deleteMenuItem: "Məhsul uğurla silindi",
      duplicateMenuItem: "Məhsul uğurla kopyalandı",
      updateZone: "Zona uğurla yeniləndi",
      createZone: "Zona uğurla yaradıldı",
      deleteZone: "Zona uğurla silindi",
      updateTable: "Masa uğurla yeniləndi",
      updateMenuSettings: "Menyu ayarları uğurla yeniləndi",
      createTable: "Masa uğurla yaradıldı",
      deleteTable: "Masa uğurla silindi",
      updateUser: "İstifadəçi uğurla yeniləndi",
      createUser: "İstifadəçi uğurla yaradıldı",
      deleteUser: "İstifadəçi uğurla silindi",
      updateQR: "QR uğurla yeniləndi",
      createQR: "QR uğurla yaradıldı",
      deleteQR: "QR uğurla silindi",
      updateOrder: "Sifariş uğurla yeniləndi",
      createOrder: "Sifariş uğurla yaradıldı",
      deleteOrder: "Sifariş uğurla silindi",
      finishedOrder: "Sifariş uğurla tamamlandı",
      updateInventoryCategory: "İnventar kateqoriyası uğurla yeniləndi",
      createInventoryCategory: "İnventar kateqoriyası uğurla yaradıldı",
      deleteInventoryCategory: "İnventar kateqoriyası uğurla silindi",
      updateInventoryItem: "İnventar maddəsi uğurla yeniləndi",
      createInventoryItem: "İnventar maddəsi uğurla yaradıldı",
      deleteInventoryItem: "İnventar maddəsi uğurla silindi",
      updateBusiness: "Biznes uğurla yeniləndi",
      createBusiness: "Biznes uğurla yaradıldı",
      deleteBusiness: "Biznes uğurla silindi",
      updateLanguage: "Dil uğurla dəyişildi",
      resetPassword: "Şifrə uğurla yeniləndi",
    },
    error: {
      common: "Nəsə səhvlik var! Zəhmət olmasa yenidən cəhd edin",
      zones: "Bu zonanı silə bilməzsiniz çünki masası mövcuddur",
      unselectedTable: "Masa seçməlisiniz",
      unselectedZone: "Zona seçməlisiniz",
      deleteCategory:
        "Bu kateqoriyanı silə bilməzsiniz, çünki onun içində məhsul var.",
    },
    warning: {
      socials: "Hələlik üzərində iş gedir, anlayışınız üçün təşəkkür edirik.",
    },
  },
  reservation: {
    reserve: "Rezervasiya et",
    reservationTime: "Rezervasiya vaxtı",
    reservationInfo: "Rezervasiya məlumatı",
    contactInfo: "Əlaqə məlumatları",
    enterName: "Adınızı daxil edin",
    enterPhoneNumber: "Telefon nömrəsi",
    bookingList: "Rezervasiya siyahısı",
    booked: "Rezervasiya olundu",
    pending: "Gözləmədə",
    createReservation: "Rezervasiya yarat",
    editReservation: "Rezervasiyanı redaktə et",
    waiting: "Gözləyir",
    today: "Bu gün",
    guestNumber: "Qonaq sayı",
    waitingTime: "Sorğu vaxtı",
    startDate: "Başlama tarixi",
    ReservationDuration: "Rezervasiya müddəti",
    comment: "Şərh",
    numberOfGuest: "Qonaq sayı",
    startTime: "Başlama vaxtı",
    duration: "Müddət",
    confirmed: "Təsdiqləndi",
    denied: "Rədd edildi",
    delete: "Sil",
    update: "Yenilə",
    searchRestaurant: "Restoran axtar",
    tableNotFound: "Seçilməyib",
    showMore: "Daha çox göstər",
    showLess: "Daha az göstər",
    reservations: "Rezervasiyalar",
    open: "Açıq",
    seeMenu: "Menyuya bax",
    reservation: "Rezervasiya",
    contact: "Əlaqə",
    place: "Yer",
    reserveeName: "Rezervasiya edən şəxsin adı",
    date: "Tarix",
    time: "Vaxt",
    reservationStatus: "Rezervasiya statusu",
    reservationDurationHelpText:
      "Qeyd: Əgər müddət seçilməzsə, avtomatik olaraq 1 saat təyin ediləcək.",
    justNow: "İndi",
    oneDay: "1 gün",
    day: "gün",
    hour: "saat",
    minute: "dəq",
    selectedTable: "Seçilmiş masa",
    confirmation: "Təsdiqlə və davam et",
    chooseRestaurant: "Restoranı seçin",
    setDate: "Nə vaxt ziyarət edirsiniz?",
    chooseTable: "Üstünlük verdiyiniz masa varmı?",
    addContact: "Kim ziyarət edir?",
    vrLinkCopied: "VR linki kopyalandı",
    statuses: {
      PENDING: "Gözləmədə",
      CONFIRMED: "Təsdiqləndi",
      DENIED: "Ləğv edildi",
      DELETED: "Silindi",
    },
    vr: {
      browserDoesNotSupport: "Sizin brauzer video teqini dəstəkləmir.",
      vrTourReservation: "VR tur rezervasiyası",
      explore: "Kəşf edin və yerinizi seçin!",
      vrView: "VR görüntü",
      explorevr: "Məkanımızı kəşf edin",
    },
    map: {
      chooseTable: "Masa seçin",
      mapReservation: "Xəritədə rezervasiya",
      chooseLocation: "Masanızın yerini seçin!",
      tables: "Masalar",
    },
    sentSuccessfully: "Sorğunuz uğurla göndərildi",
    successMessage:
      "Yeniliklər telefon nömrənizə göndəriləcək və rezervasiya səhifəsində statusunuzu yoxlaya bilərsiniz",
  },
  socials: {
    instagram: "Instagram",
    email: "Email",
    whatsapp: "Whatsapp",
  },
  months: {
    january: "Yanvar",
    february: "Fevral",
    march: "Mart",
    april: "Aprel",
    may: "May",
    june: "İyun",
    july: "İyul",
    august: "Avqust",
    september: "Sentyabr",
    october: "Oktyabr",
    november: "Noyabr",
    december: "Dekabr",
  },
  weekdays: {
    monday: "Bazar ertəsi",
    tuesday: "Çərşənbə axşamı",
    wednesday: "Çərşənbə",
    thursday: "Cümə axşamı",
    friday: "Cümə",
    saturday: "Şənbə",
    sunday: "Bazar",
  },
  periods: {
    hourly: "Saatlıq",
    daily: "Günlük",
    weekly: "Həftəlik",
    monthly: "Aylıq",
  },
  chat: {
    typeHere: "Bura yazın...",
    chat: "Söhbət",
    allMessages: "Bütün Mesajlar",
    unreadMessages: "Oxunmamış Mesajlar",
    selectTopic: "Zəhmət olmasa söhbət seçin.",
    noMessages: "Mesaj yoxdur.",
  },
  kitchen: {
    stageNew: "Yeni",
    stageProgress: "Hazırlanır",
    stageDone: "Hazır",
  },
  inventory: {
    productList: "Məhsul Siyahısı",
    product: "məhsul",
    stock: "Stok",
    warningAmountInfo:
      "Bitmək üzrə olan zaman xəbərdarlıq üçün minimum miqdarı seçin",
    productname: "Məhsulun adı",
  },
  emptyStates: {
    noPrinters: "Hələ printer əlavə edilməyib",
    noOption: "Seçim yoxdur",
    noOrder: "Sifariş yoxdur",
    noItem: "Məhsul yoxdur",
    noLanguage: "Əlavə etmək üçün dil yoxdur",
    noTableData: "Bu səhifədə məlumat yoxdur",
    noOrderedCategory:
      "Bu kateqoriyada sifariş edilmiş məhsul nəticələri yoxdur",
    noChat: "Sifarişin olmaması səbəbindən heç bir söhbətiniz yoxdur",
    noUnread: "Oxunmamış mesaj yoxdur.",
    noOrders: "Sifarişlər Yoxdur",
    noGuests: "Qonaq yoxdur",
    noSearchResults: "Axtarış Nəticəsi Yoxdur",
    noPendingReservations: "Gözləyən rezervasiyalar yoxdur.",
    noReservationDescription: "Hələ heç bir rezervasiyanız yoxdur",
    noReservations: "Rezervasiyalar yoxdur",
    notOpenForReservations: "Bu restoran rezervasyonlara açık değildir.",
    noBusiness: "Biznes Yoxdur",
    noBusinessContent: "Biznes haqqında əlavə məlumat yoxdur",
    noBusinessDescription:
      "Təəssüf ki, bütün restoran rezervasiyaları bağlanıb.",
  },
  info: {
    itemAmount:
      "Nömrə, kiloqram (kg), qram (g), litr (l) və ya millilitr (ml) kimi vahidlərlə göstərilən məhsul miqdarını göstərir.",
    itemModificationOptionMaxNumberInfo:
      "Maksimum say,bu seçimin tətbiq edilə biləcəyi maksimum limiti göstərir.",
    general: "Ümumi məlumat",
    detail: "Ətraflı məlumat",
  },
  stepper: {
    signupMethod: "Qeydiyyat metodu",
    otpVerification: "OTP Doğrulama",
    userDetail: "İstifadəçi Məlumatları",
    passwordVerification: "Şifrə Doğrulama",
    setPinCode: "PIN Kodu",
  },
  general: {
    companyName: "iLoyal",
    termsAndConditions: "Ərazi şərtləri",
    privacyPolicy: "Gizlilik Siyasəti",
    allRightReserved: "Bütün hüquqlar qorunur.",
    copyRight: "© {{year}} {{value}}.",
    joinUs: "Bizə qoşulun {{emoji}}",
    madeWith: " tərəfindən {{emoji}} ilə hazırlanmışdır",
    madeBy: "<strong>iLoyal</strong> tərəfindən hazırlanmışdır",
    scanQR: "Yeni sifariş üçün QR kodunu skan edin",
    name: "Dostlarınıza siz olduğunuzu göstərin!",
    profilePicture: "Profil şəkiliniz",
  },
  optionTypes: {
    None: "Yoxdur",
  },
  businesses: {
    allBusinesses: "Bütün Bizneslər",
    specialOffers: "Xüsusi Təkliflər",
    mostPopular: "Ən Məşhur",
    coffeeShop: "Kofe Dükanı",
    nationalCuisine: "Milli Mətbəx",
    nationalCuisineExtended: "Milli Mətbəxxxxxxxx",
    seaside: "Dəniz Kənarı",
    information: "Məlumat",
    search: "Axtar...",
    seeAll: "Hamısına bax",
    seeLess: "Daha az göstər",
  },
  policy: {
    termsDataInfo:
      "Davam etməklə,  <strong> Şərtlər və Məxfilik Siyasəti </strong>ilə razılaşırsınız.",

    joinUsTermsData: {
      introduction: {
        title: "1. Giriş",
        content: {
          point1:
            "Xidmətlərimizdən istifadə etməklə, bu Xidmət Şərtləri və Məxfilik Siyasəti ilə razılaşırsınız. Onları diqqətlə oxumağınızı xahiş edirik.",
        },
      },
      userConsent: {
        title: "2. İstifadəçi Razılığı",
        content: {
          point1:
            "Xidmətlərimizə daxil olmaqla və ya istifadə etməklə, məlumatlarınızın bu Xidmət Şərtləri və Məxfilik Siyasətində təsvir olunduğu şəkildə toplanmasına, istifadəsinə və paylaşılmasına razı olursunuz. Bu şərtlərlə razı deyilsinizsə, xahiş edirik, xidmətlərimizdən istifadə etməyin.",
        },
      },
      dataCollection: {
        title: "3. Məlumatın Toplanması",
        content: {
          point1:
            "Biz sizə birbaşa verdiyiniz məlumatları toplayırıq, məsələn, hesab yaratdığınız, alış-veriş etdiyiniz və ya dəstək üçün bizimlə əlaqə saxladığınız zaman. Həmçinin, avtomatik olaraq IP ünvanınız, brauzer növü və istifadə məlumatlarınızı toplamağımız mümkündür.",
        },
      },
      dataUse: {
        title: "4. Məlumatın İstifadəsi",
        content: {
          point1:
            "Məlumatınız xidmətlərimizi təqdim etmək, saxlamaq və təkmilləşdirmək üçün istifadə olunur. Bu, əməliyyatların işlənməsini, ünsiyyətlərin göndərilməsini və təcrübənizin fərdiləşdirilməsini əhatə edir.",
        },
      },
      dataProtection: {
        title: "5. Məlumatın Qorunması",
        content: {
          point1:
            "Məlumatlarınızı qorumağa sadiqik. Məlumatlarınızı icazəsiz daxil olma, dəyişiklik etmə, açıqlama və ya məhv etmə qarşısında qorumaq üçün müxtəlif təhlükəsizlik tədbirləri tətbiq edirik.",
        },
      },
      dataSharing: {
        title: "6. Məlumatın Paylaşılması",
        content: {
          point1:
            "Şəxsi məlumatlarınızı satmırıq. Xidmətlərimizi idarə etməyə, işimizi aparmağa və ya sizə xidmət təqdim etməyə kömək edən etibarlı üçüncü tərəflərlə məlumatları paylaşa bilərik, şərt ki, bu tərəflər bu məlumatları gizli saxlayacaqlarına razı olsunlar.",
        },
      },
      userRights: {
        title: "7. İstifadəçi Hüquqları",
        content: {
          point1:
            "Şəxsi məlumatlarınıza giriş, düzəliş etmək və ya silmək hüququnuz var. Məlumatlarınızın bəzi istifadələrindən və açıqlamalarından imtina edə bilərsiniz.",
        },
      },
      general: {
        title: "",
        content: {
          point1:
            "Xidmətlərimizdən istifadə etməklə, bu şərtləri oxuduğunuzu və başa düşdüyünüzü və onlara riayət etməyə razı olduğunuzu qəbul edirsiniz.",
        },
      },
    },

    signUpTermsData: {
      agreement: {
        title: "1. Müqavilə Şərtləri",
        content: {
          point1:
            "1.1 Lisenziya verən şəxs bu müqavilənin şərtləri altında proqram təminatını təmin etməlidir və müştəri bu lisenziya üçün ödəniş etməlidir.",
          point2:
            "1.2 Aylıq lisenziya (abunə) haqqı, müştərinin ehtiyaclarına uyğun olaraq iLoyal tərəfindən təqdim olunan xüsusiyyətlərin müxtəlifliyinə əsaslanaraq müəyyən edilir. Lisenziya (abunə) haqqındakı dəyişikliklər yalnız Müştəri ilə iLoyal arasında qarşılıqlı razılaşma əsasında edilə bilər. Aylıq lisenziya (abunə) müddəti müqavilənin imzalandığı tarixdən başlayır.",
        },
      },
      license: {
        title: "2. Lisenziya Şərtləri",
        content: {
          point1:
            "2.1 Lisenziya verən şəxs sadə lisenziya şərtləri altında aşağıdakı hüquqları verir:",
          point2:
            "2.1.1 Proqram təminatının istifadəsini təmin etmək və onun məqsədinə uyğun fəaliyyətini müəyyən etmək.",
          point3: "2.2 Lisenziya üçün aşağıdakılar QADAĞANDIR:",
          point4:
            "2.2.1 İstifadəçi tərəfindən proqram təminatının kodunun çıxarılması.",
          point5:
            "2.2.2 İstifadəçi tərəfindən proqram təminatının bir və ya daha çox surətinin yaradılması.",
          point6: "2.2.3 Proqram təminatının və onun surətlərinin satılması.",
          point7: "2.2.4 Proqram təminatının ictimaiyyətə açıq olması.",
        },
      },
      payment: {
        title: "3. Lisenziya Ödənişi",
        content: {
          point1:
            "3.1 Müştəri bu müqavilədə göstərilən lisenziya(lar) üçün fakturada göstərilən məbləği ödəməyə razıdır.",
          point2:
            "3.2 Lisenziyaların qiymətləri lisenziya verənin rəsmi veb saytında göstərilmişdir.",
          point3:
            "3.3 Tərəflər arasında müqavilənin ləğv edilməsi halında, lisenziya haqqı müzakirə edilə bilən və geri qaytarılmayan məbləğdir.",
          point4:
            "3.4 Lisenziya verən şəxs tərəfindən verilmiş fakturaya əsasən lisenziya haqqı ödənildikdən sonra, siz cari Lisenziya Müqaviləsinin şərtləri ilə razılaşmış sayılırsınız və bu müqavilə siz və lisenziya verən şəxs arasında bağlanmış hesab olunur.",
          point5:
            "3.5 Lisenziya verən şəxs bu müqaviləni tək tərəfli olaraq dəyişdirmək hüququnu özündə saxlayır.",
          point6:
            "3.6 Müvafiq məbləğlər lisenziya verənin bank hesabına yatırıldıqdan sonra, müştərinin bütün ödəniş öhdəlikləri yerinə yetirilmiş hesab olunur.",
        },
      },
      dataProtection: {
        title: "4. Məlumatların Mühafizəsi",
        content: {
          point1:
            "4.1 Bütün müştəri məlumatları lisenziya verən şəxs tərəfindən qorunur.",
          point2:
            "4.2 Bizim bulud hesablarımızda saxlanılan müştəri məlumatları üçüncü tərəflərlə paylaşılmır.",
        },
      },
    },
  },
  auth: {
    createAnAccount: "Sizə hesab yaradaq?",
    register: "Qeydiyyatdan keç",
    verifyOtp: "OTP-ni təsdiqlə",
    profile: "Profil",
    signUp: "Qeydiyyatdan keç",
    otpSentTo: "OTP göndərildi",
    otpCode: "OTP Kodu",
    letKnow: "Gəlin Sizi Tanıyaq",
    gender: "Cins",
    saveAndFinish: "Yadda saxla və bitir",
    message: {
      first: "əyləncəyə qoşul!",
      second: "rahatlığı hiss et!",
      third: "təklifləri qaçırma!",
      fourth: "vaxtına qənaət et!",
    },
    genders: {
      male: "Kişi",
      female: "Qadın",
      other: "Digər",
    },
    loginYourAccount: "Hesabınıza daxil olun",
    forgotPassword: "Şifrəni unutmusunuz?",
    forgotPasswordTitle: "Şifrəni unutmusunuz",
    forgotPasswordDesc:
      "{{method}} daxil edin və sizə OTP təsdiqləmə kodu göndərəcəyik",
    setNewPassword: "Yeni şifrə təyin edin",
    setNewPasswordDesc: "Yeni şifrənizi daxil edin",
    signUpWith: "və ya ilə qeydiyyatdan keçin",
    withoutSignUp: "Qeydiyyatsız giriş",
    loginAccount: "Hesabınıza daxil olun",
    email: "E-poçt",
    phoneNumber: "telefon nömrəsi",
    info: "Məlumat",
    youAlreadyHaveAccount: "Sizin artıq qeydiyyatdan keçmiş hesabınız var: ",
    clickButtonToLogin: "Daxil olmaq üçün bu düyməni basın",
    resendingIsAvailable: "Təkrar göndərmə mümkündür",
    resendingCodeWillBeAvailableIn: "Kodun təkrar göndərilməsi mümkün olacaq ",
    createGuestAccountDesc:
      "iLoyalda qeydiyyatdan keçməklə endirim və əlavə funksiyalardan yararlanmaq şansı əldə et!",
  },
  myProfile: {
    title: "Hesab",
    editProfile: "Profilə Düzəliş Et",
    orderHistory: "Sifariş Tarixi",
    favorites: "Favorilər",
    chat: "Çat",
    gameHistory: "Oyun Tarixi",
    helpCenter: "Kömək Mərkəzi",
    settings: "Tənzimləmələr",
    resetPassword: {
      resetPassword: "Şifrəni Sıfırla",
      setNewPassword: "Yeni Şifrə Təyin Et",
      enterCurrentPassword:
        "Yeni şifrə təyin etmək üçün, zəhmət olmasa əvvəlcə cari şifrənizi daxil edin.",
      currentPassword: "Cari Şifrə *",
      newPassword: "Yeni Şifrə *",
    },
  },

  record: {
    talkToMe: "Mənimlə danış",
    magicOrder: "Sehrli Sifariş",
    magicSearch: "Sehrli axtarış",
    start: "Başla",
    stop: "Dayandır",
    magicOffer: "Sehrli Təklif",
    results: "Nəticələr",
    addedToBasket: "Səbətə əlavə edildi",
    youCanUseMicrophone: "Mikrofon istifadə edə bilərsiniz",
    magicOrderError:
      "Sizin sorğunuz menyu ilə əlaqəli görünmür. Zəhmət olmasa, yemək və ya menyu məhsulları ilə bağlı daha konkret bir sorğu verin.",
    introductionLoya: "Salam, mən Loya.",
    askMeAnything: "Məndən bir şey soruş!",
    askLoya: "Loyadan soruş",
    loyaIsTyping: "Loya yazır",
  },

  recommend: {
    fitMyBudget: "Büdcəmə uyğun yeməkləri tövsiyə edin.",
    tastiestDishes: "Ən ucuz, lakin ən dadlı yeməkləri seçin",
    delicious: "Ən dadlı yeməklərinizi təklif edin",
    smtQuick: "Mənə tez hazırlanan bir şey ver",
  },

  termsAndCondition: TermsAndConditionAz,
  privacyPolicy: PrivacyPolicyAz,
  devices: {
    addPrinter: "Printer əlavə et",
    testPrinter: "Printeri test et",
    deletePrinter: "Printeri sil",
    connectedDevices: "Bağlı cihazlar",
    availableDevices: "Mövcud cihazlar",
    notFound: "Tapılmadı!",
  },
};
export default TRANSLATION;
