import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { updateBasket } from "redux/slices/basketStore";
import { STORE_NAMES } from "utils/constants/redux";
import {
  filterMenuItemByIsPublishedAndSchedule,
  findItemById,
} from "utils/general";
import useTimeout from "utils/hooks/useTimeout";
import { getBasketModificationsWithDefaultValues } from "utils/helpers";
import Loya from "assets/icons/menuv2/Loya.png";
import MenuCard from "pages/client/magic-order/magic-card/MagicCard";

import "./BestChoiceModal.scss";

const ResultBody = ({ result, handleAddToBasket }) => {
  const filteredResultItems = filterMenuItemByIsPublishedAndSchedule(
    result.items
  );

  const [addBasketAnimation, setAddBasketAnimation] = useState(false);
  const handleAddBasketCustom = (item) => {
    handleAddToBasket([item.id]);
  };

  useTimeout({
    callback: () => {
      setAddBasketAnimation(false);
    },
    delay: addBasketAnimation ? 1000 : null,
  });

  return (
    filteredResultItems.length > 0 && (
      <div className="ResultItems">
        <div className="ResultItemTitle">
          <h5 className="SemiBold">{result.title}</h5>
          <h6 className="Medium">{result.reason}</h6>
        </div>
        <div className="ResultItemDetails">
          {filteredResultItems.map((item, index) => (
            <MenuCard
              key={index}
              menuItem={item}
              onAddToBasket={handleAddBasketCustom}
            />
          ))}
        </div>
      </div>
    )
  );
};

ResultBody.propTypes = {
  handleAddToBasket: PropTypes.func,

  result: PropTypes.object,
};

const BestChoiceModal = ({ results }) => {
  const menu = useSelector((state) => state[STORE_NAMES.menu].data);
  const dispatch = useDispatch();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);

  const handleAddToBasket = (selectedItems) => {
    const addedMenuItems = selectedItems.map((itemId) =>
      findItemById(itemId, menu)
    );

    addedMenuItems.forEach((menuItem) => {
      const modifications = getBasketModificationsWithDefaultValues(
        menuItem.modifications
      );
      const filteredModifications = modifications.filter((modification) => {
        return modification.options.length !== 0;
      });
      dispatch(
        updateBasket({
          menuItem: {
            id: menuItem.id,
            modifications: filteredModifications.map((modification) => {
              return {
                id: modification.id,
                options: modification.options.map((option) => {
                  return {
                    id: option.id,
                    count: option.count ?? 1,
                  };
                }),
              };
            }),
          },
          count: 1,
          userId: guestId,
        })
      );
    });
  };

  return (
    <div className="BestChoiceModalBody">
      <div className="LoyaIconWrapper">
        <img className="LoyaIcon" src={Loya} alt="" />
      </div>

      <div className="ResultsWrapper">
        {results.map((result, index) => {
          return (
            <ResultBody
              key={index}
              result={result}
              handleAddToBasket={handleAddToBasket}
            />
          );
        })}
      </div>
    </div>
  );
};
BestChoiceModal.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object),
};
export default BestChoiceModal;
