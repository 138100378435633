import { createSlice } from "@reduxjs/toolkit";
import {
  createGuestAsync,
  getGuestByTokenAsync,
  loginGuestAsync,
  registerGuestAsync,
  updateGuestInfoAsync,
} from "redux/actions/guestAction";

import { STORE_NAMES } from "utils/constants/redux";
import { MENU_ORDER_MODES } from "utils/constants/data/base";
import { LOCAL_STORAGE_VARIABLES } from "utils/constants/global";

const initialState = {
  id: null,
  firstName: null,
  lastName: null,
  oAuthProvider: null,
  phoneNumber: null,
  profilePicture: null,
  email: null,
  gender: "other",
  dateOfBirth: null,
  isOtpVerified: false,
  hasSignedUp: false,
  selectedMenuView: null,
  menuFilters: {
    priceRange: { min: 0, max: 0 },
    selectedCategories: [],
    selectedTags: [],
    showDiscountedItems: null,
    sortBy: null,
  },
  menuOrderMode: MENU_ORDER_MODES.EAT_IN,
  thunkAPIStates: {
    updateGuestInfo: false,
  },
};

export const guestStore = createSlice({
  name: STORE_NAMES.guest,
  initialState: initialState,
  reducers: {
    setGuestId: (state, action) => {
      const id = action.payload;
      return { ...state, id };
    },
    setGuestInfo: (state, action) => {
      const { id, profilePicture, firstName, lastName, phoneNumber } =
        action.payload;
      return { ...state, id, profilePicture, firstName, lastName, phoneNumber };
    },
    selectMenuView: (state, action) => {
      const menuViewId = action.payload;
      return { ...state, selectedMenuView: menuViewId };
    },
    setFilterOptions: (state, action) => {
      const {
        selectedTags,
        priceRange,
        selectedCategories,
        showDiscountedItems,
        sortBy,
      } = action.payload;
      return {
        ...state,
        menuFilters: {
          ...state.menuFilters,
          selectedCategories,
          selectedTags,
          priceRange,
          showDiscountedItems,
          sortBy,
        },
      };
    },
    setFilterMaxPrice: (state, action) => {
      const updatedMaxPrice = action.payload;
      return {
        ...state,
        menuFilters: {
          ...initialState.menuFilters,
          priceRange: {
            ...initialState.menuFilters.priceRange,
            max: updatedMaxPrice,
          },
        },
      };
    },
    resetMenuFilters: (state) => {
      return { ...state, menuFilters: { ...initialState.menuFilters } };
    },
    setMenuOrderMode: (state, action) => {
      const updatedMenuOrderMode = action.payload;
      return { ...state, menuOrderMode: updatedMenuOrderMode };
    },
    resetGuestStore: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createGuestAsync.fulfilled, (state, { payload }) => {
      const {
        id,
        firstName,
        lastName,
        phoneNumber,
        email,
        profilePic: profilePicture,
        dateOfBirth,
        gender,
        hasSignedUp,
        isOtpVerified,
      } = payload;
      return {
        ...state,
        id,
        firstName,
        lastName,
        phoneNumber,
        email,
        profilePicture,
        dateOfBirth,
        gender,
        hasSignedUp,
        isOtpVerified,
      };
    });
    builder.addCase(registerGuestAsync.fulfilled, (state, { payload }) => {
      localStorage.setItem(
        LOCAL_STORAGE_VARIABLES.accessTokenForGuest,
        payload.access_token
      );
      return {
        ...state,
        ...payload.guest,
        profilePicture: payload.guest.profilePic,
      };
    });
    builder.addCase(loginGuestAsync.fulfilled, (state, { payload }) => {
      localStorage.setItem(
        LOCAL_STORAGE_VARIABLES.accessTokenForGuest,
        payload.access_token
      );
      return {
        ...state,
        ...payload.guest,
        profilePicture: payload.guest.profilePic,
      };
    });
    builder.addCase(getGuestByTokenAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        ...payload,
        profilePicture: payload.profilePic,
      };
    });
    builder.addCase(updateGuestInfoAsync.pending, (state) => {
      return {
        ...state,
        // thunkAPIStates: { ...state.thunkAPIStates, updateGuestInfo: true },
      };
    });
    builder.addCase(updateGuestInfoAsync.rejected, (state) => {
      return {
        ...state,
        // thunkAPIStates: { ...state.thunkAPIStates, updateGuestInfo: false },
      };
    });
    builder.addCase(updateGuestInfoAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        ...payload,
        profilePicture: payload.profilePic,
        // thunkAPIStates: { ...state.thunkAPIStates, updateGuestInfo: false },
      };
    });
  },
});
export const {
  setGuestId,
  setGuestInfo,
  resetGuestStore,
  selectMenuView,
  setFilterOptions,
  resetMenuFilters,
  setMenuOrderMode,
  setFilterMaxPrice
} = guestStore.actions;

export default guestStore.reducer;
