import React from "react";
import { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import MagicOrderIcon from "assets/images/compress.gif";

import "./MagicOrderLoading.scss";

const MagicOrderLoading = () => {
  return (
    <div className="MagicOrderLoadingContainer">
      <div className="MagicOrderLoadingSpinner"></div>
    </div>
  );
};

const MagicOrderIconComponent = ({ onClick }) => {
  const [isGifLoaded, setIsGifLoaded] = useState(false);
  return (
    <div
      className={cx("MagicOrderIconContainer", {
        isOverflowHidden: isGifLoaded,
      })}
      onClick={onClick}
    >
      <img
        src={MagicOrderIcon}
        onLoad={() => setIsGifLoaded(true)}
        alt="Magic Order"
        className="MagicOrderGif"
        style={{
          display: isGifLoaded ? "inline-block" : "none",
        }}
      />
      {!isGifLoaded && <MagicOrderLoading />}
    </div>
  );
};
MagicOrderIconComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
};
export default MagicOrderIconComponent;
