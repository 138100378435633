import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import EmptyState from "components/admin/empty-state/EmptyState";
import useLanguage from "utils/hooks/useLanguage";
import MenuItemList from "components/menu-item-list/MenuItemList";
import { useMixpanel } from "utils/context-api/MixpanelContext";
import { filterMenuByCriteria } from "utils/helper-functions/menu-helper/menu-filter-helper";
import { getBasketModificationsWithDefaultValues } from "utils/helpers";
import { updateBasket, updateFavorites } from "redux/slices/basketStore";
import { MP_EVENTS, MP_PROP_NAMES } from "utils/constants/mixpanel";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import { resetMenuFilters } from "redux/slices/guestStore";
import { ReactComponent as FilterIcon } from "assets/icons/form/Filter-2.svg";
import SEARCH_ICON from "assets/icons/chat/search.svg";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";

import styles from "./FilterResultPage.module.scss";

const FilterResultPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { trackMixpanel } = useMixpanel();
  const { displayDataByLanguage } = useLanguage();
  const [searchParams, setSearchParams] = useSearchParams();
  const { businessId } = useParams();
  const userId = useSelector((state) => state[STORE_NAMES.guest].id);
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const menuFilters = useSelector(
    (state) => state[STORE_NAMES.guest].menuFilters
  );
  const { categories } = useMenuHelper();

  const filteredMenu = filterMenuByCriteria({ categories, menuFilters });

  const goBackHandler = () => {
    dispatch(resetMenuFilters());
    const backUrl = `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`;
    navigate(backUrl);
  };

  const handleOnFavorite = (menuItem) => {
    const itemName = displayDataByLanguage(menuItem.name);

    dispatch(
      updateFavorites({
        userId,
        menuItemID: menuItem.id,
      })
    );
    trackMixpanel(`${MP_EVENTS.menu.liked}`, {
      [MP_PROP_NAMES.itemId]: menuItem.id,
      [MP_PROP_NAMES.itemName]: itemName,
    });
  };

  const handleAddToBasket = (menuItem) => {
    const modifications = getBasketModificationsWithDefaultValues(
      menuItem.modifications
    ).filter((modification) => modification.options.length > 0);

    const payload = {
      menuItem: {
        id: menuItem.id,
        modifications: modifications.map((modification) => {
          return {
            id: modification.id,
            options: modification.options.map((option) => {
              return {
                id: option.id,
                count: option.count ?? 1,
              };
            }),
          };
        }),
      },
      count: 1,
      userId: guestId,
    };

    dispatch(updateBasket(payload));
  };

  const handleOnClick = (item) => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItem}/${item.id}?${QUERY_PARAMS.from}=${pathname}`
    );
  };

  const filterButton = (
    <div
      className={styles.FilterButtonContent}
      onClick={() => {
        searchParams.set(
          QUERY_PARAMS.showPopup,
          QUERY_PARAMS.popupPages.filter
        );
        setSearchParams(searchParams);
      }}
    >
      <span className={styles.hasFilter}></span>
      <FilterIcon />
    </div>
  );

  const noFilterResults = (
    <EmptyState
      description={t("emptyStates.noSearchResults")}
      icon={SEARCH_ICON}
      isAdmin={false}
      className={styles.MenuNoFilterResults}
    />
  );

  return (
    <MenuItemList
      onGoBack={goBackHandler}
      title={t("record.results")}
      categories={filteredMenu}
      onClick={handleOnClick}
      onFavorite={handleOnFavorite}
      addToBasket={handleAddToBasket}
      actionButtonComponent={filterButton}
      emptyStateComponent={noFilterResults}
    />
  );
};

export default FilterResultPage;
