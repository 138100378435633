import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { GUEST_SIGN_UP_STEP_TYPES } from "pages/client/auth/ClientAuthHelper";
import SignUpMainForm from "pages/client/auth/sign-up/sign-up-main-form/SignUpMainForm";
import SignUpVerifyOtp from "pages/client/auth/sign-up/sign-up-verify-otp/SignUpVerifyOtp";
import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import { ROUTE_NAME } from "utils/constants/routes";
import SignUpDetailForm from "pages/client/auth/sign-up/sign-up-detail-form/SignUpDetailForm";
import useCountdown from "utils/hooks/useCountdown";
import { SIGN_IN_METHOD as SIGN_UP_METHOD } from "pages/common/login/sign-in/SignIn";
import GuestAlreadyHaveAccountModal from "components/guest-already-have-account-modal/GuestAlreadyHaveAccount";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { setShowGuestAuthModal } from "redux/slices/userStore";

import "./SignUpStepper.scss";
const SignUpStepperProgressBar = ({ activeStep, steps }) => {
  const numberOfSteps = Object.keys(steps).length;
  const activeStepIndex = Object.keys(steps).indexOf(activeStep.key);
  return (
    <div className="StepperProgressBar">
      {Object.keys(steps).map((stepKey, index) => {
        const stepWidth = `calc((100% - ${
          (numberOfSteps - 1) * 16
        }px) / ${numberOfSteps})`;
        return (
          <div
            key={stepKey}
            className={cx("StepperProgressBarLine", {
              isPassed: index <= activeStepIndex,
            })}
            style={{ width: stepWidth }}
          ></div>
        );
      })}
    </div>
  );
};

SignUpStepperProgressBar.propTypes = {
  activeStep: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
  steps: PropTypes.object.isRequired,
};

const SignUpStepper = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { businessId } = useParams();

  const [openSlideGuestAuthModal, setOpenSlideGuestAuthModal] =
    useOutsideClick();

  const [activeStep, setActiveStep] = useState(
    GUEST_SIGN_UP_STEP_TYPES.mainForm
  );
  const [formData, setFormData] = useState({
    phoneNumber: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    otpCode: "",
    otpToken: "",
    dateOfBirth: "",
    gender: "",
    profilePic: "",
    otpSendCount: 3,
  });

  const [steps] = useState(GUEST_SIGN_UP_STEP_TYPES);
  const [counter, setCounter] = useCountdown(0);

  const [selectedMethod, setSelectedMethod] = useState(
    SIGN_UP_METHOD.phoneNumber
  );
  const activeStepIndex = Object.keys(steps).indexOf(activeStep.key);
  const dynamicWidth = `${Object.keys(steps).length * 100}%`;

  // const nextStepKey = Object.keys(steps)[activeStepIndex + 1];

  // const handleNextStep = () => {
  //   if (activeStepIndex === 2) {
  //     return navigate(`${ROUTE_NAME.client}${ROUTE_NAME.auth}`);
  //   }
  //   setActiveStep(steps[nextStepKey]);
  // };

  const handleBackStep = () => {
    const backStepKey = Object.keys(steps)[activeStepIndex - 1];

    if (activeStepIndex === 0) {
      return businessId
        ? navigate(
            `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.auth}`
          )
        : navigate(`${ROUTE_NAME.client}${ROUTE_NAME.auth}`);
    }

    setActiveStep(steps[backStepKey]);
  };

  const handleOnCancel = (e) => {
    e.stopPropagation();
    setOpenSlideGuestAuthModal(false);
    dispatch(setShowGuestAuthModal(false));
  };

  const GuestAuthSignUpHeader = (
    <div className="GuestAuthSignUpHeader">
      {activeStep.type !== steps.detailForm.type && (
        <BackButton
          onClick={handleBackStep}
          type={BACK_BUTTON_ENUMS.types.TYPE_D}
        />
      )}
      <h2 className="SemiBold">{t("auth.signUp")}</h2>
    </div>
  );

  return (
    <div className="SignUpStepper">
      {GuestAuthSignUpHeader}
      <SignUpStepperProgressBar activeStep={activeStep} steps={steps} />
      <div
        className="SignUpStepperWrapper"
        style={{
          width: dynamicWidth,
          marginLeft: `-${activeStepIndex * 100}%`,
        }}
      >
        <SignUpMainForm
          setCounter={setCounter}
          setFormData={setFormData}
          formData={formData}
          selectedMethod={selectedMethod}
          setSelectedMethod={setSelectedMethod}
          setActiveStep={setActiveStep}
          setOpenSlideGuestAuthModal={setOpenSlideGuestAuthModal}
        />

        <SignUpVerifyOtp
          selectedMethod={selectedMethod}
          counter={counter}
          setCounter={setCounter}
          setFormData={setFormData}
          formData={formData}
          setActiveStep={setActiveStep}
        />
        <SignUpDetailForm setFormData={setFormData} formData={formData} />
        <GuestAlreadyHaveAccountModal
          openSlide={openSlideGuestAuthModal}
          setOpenSlide={setOpenSlideGuestAuthModal}
          onCancel={(e) => handleOnCancel(e)}
          selectedMethod={selectedMethod}
          userIdentifier={
            selectedMethod === SIGN_UP_METHOD.phoneNumber
              ? formData.phoneNumber
              : formData.email
          }
        />
      </div>
    </div>
  );
};
SignUpStepper.propTypes = {
  formData: PropTypes.shape({
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    password: PropTypes.string,
    otpCode: PropTypes.string,
    otpToken: PropTypes.string,
    dateOfBirth: PropTypes.string,
    gender: PropTypes.string,
    profilePic: PropTypes.string,
  }),
  setFormData: PropTypes.func,
  counter: PropTypes.number,
  setCounter: PropTypes.func,
  selectedMethod: PropTypes.oneOf(["phoneNumber", "email"]),
  setSelectedMethod: PropTypes.func,
  setActiveStep: PropTypes.func,
};

export default SignUpStepper;
