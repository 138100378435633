import React, { createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { STORE_NAMES } from "utils/constants/redux";
import { MENU_VIEW_ENUMS } from "utils/constants/data/base";
import {
  filterCategoriesAndItsMenuItemsByIsArchivedAndIsPublished,
  findHighestDefaultMenuItemPrice,
  sortCategoriesWithMenuItemsByPlaceInTheList,
} from "utils/helpers";
import { getAllTagsOfMenu } from "utils/helper-functions/menu-helper/menu-filter-helper";

const GuestMenuContext = createContext();

const GuestMenuProvider = ({ children }) => {
  const menu = useSelector((state) => state[STORE_NAMES.menu].data);
  const allTags = menu?.tags || [];
  const defaultMenuViewType = useMemo(() => {
    const menuViewTypes = Object.values(MENU_VIEW_ENUMS);
    if (!menuViewTypes.length) return null;
    const defaultView = menu?.defaultMenuView;
    const selectedMenuView = menuViewTypes.find(
      (type) => type.id === defaultView
    );
    return selectedMenuView?.key || menuViewTypes[0].key;
  }, [menu]);

  const categories = useMemo(() => {
    if (!menu?.categories && menu?.categories.length === 0) {
      return [];
    }
    const filteredData =
      filterCategoriesAndItsMenuItemsByIsArchivedAndIsPublished(
        menu.categories
      );

    return sortCategoriesWithMenuItemsByPlaceInTheList(filteredData);
  }, [menu]);

  const existTags = useMemo(() => {
    if (categories.length === 0 || allTags.length === 0) return [];
    return getAllTagsOfMenu({ categories, allTags });
  }, [categories]);

  const highestMenuItemPrice = useMemo(() => {
    if (categories.length === 0) return 0;

    return findHighestDefaultMenuItemPrice(categories);
  }, [categories]);

  const shouldDisplayMenuItemImage =
    defaultMenuViewType !== MENU_VIEW_ENUMS.WITHOUT_IMAGE.key;

  return (
    <GuestMenuContext.Provider
      value={{
        shouldDisplayMenuItemImage,
        categories,
        existTags,
        highestMenuItemPrice,
      }}
    >
      {children}
    </GuestMenuContext.Provider>
  );
};

export const useMenuHelper = () => {
  return useContext(GuestMenuContext);
};

GuestMenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GuestMenuProvider;
