import React from "react";
import cx from "classnames";

import { useGuestLayout } from "pages/client/menu-v2/GuestLayoutContext";
import { TAB_BAR_HEIGHT } from "pages/client/menu-v2/tab-bar/TabBar";
import { ReactComponent as ArrowIcon } from "assets/icons/arrows/ArrowDown.svg";

import styles from "./ScrollTop.module.scss";

const SCROLL_TOP_BUTTON_HEIGHT = 36;

const ScrollTop = () => {
  const { isTabBarHidden, isScrollTopHidden } = useGuestLayout();

  const handleOnClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollTopButtonStyle = {
    bottom: isScrollTopHidden
      ? `-${SCROLL_TOP_BUTTON_HEIGHT}px`
      : isTabBarHidden
      ? "20px"
      : `${TAB_BAR_HEIGHT + 20}px`,
    height: `${SCROLL_TOP_BUTTON_HEIGHT}px`,
  };

  return (
    <button
      className={cx(styles.Container)}
      style={scrollTopButtonStyle}
      onClick={handleOnClick}
    >
      <ArrowIcon />
    </button>
  );
};

export default ScrollTop;
