import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import Category from "components/elements/category/Category";
import MenuItemSelected from "pages/client/menu-item-selected/MenuItemSelected";
import { STORE_NAMES } from "utils/constants/redux";
import EmptyMenu from "assets/icons/menu/empty-menu.svg";
import {
  filterCategoryByIsPublishedAndSchedule,
  filterMenuItemByIsPublishedAndSchedule,
  sortCategoriesAndMenuItems,
} from "utils/general";
import EmptyState from "components/admin/empty-state/EmptyState";
import { MENU_VIEW_ENUMS } from "utils/constants/data/base";
import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import { QUERY_PARAMS } from "utils/constants/routes";
import useLanguage from "utils/hooks/useLanguage";
import { ImageVisibilityProvider } from "utils/context-api/ImageVisibilityContext";
import SearchIcon from "assets/icons/chat/search.svg";
import { advanceSearch } from "utils/algorithms";
import AdminOrderMenuSection from "pages/admin/admin-pages/admin-order/admin-order-menu-section/AdminOrderMenuSection";

import "./AdminOrderMenuDisplay.scss";

export const ENUMS = {
  types: {
    GUEST: "GUEST",
    ADMIN: "ADMIN",
  },
};

const AdminOrderMenuDisplay = ({
  menuViewType = MENU_VIEW_ENUMS.SCROLL.id,
  onClick,
  onAdd,
  distanceLeft,
  type,
  hasSearchInput = false,
  isOrderSection = false,
  menu,
  scrollElement,
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const rawCategories =
    filterCategoryByIsPublishedAndSchedule(menu?.categories) || [];
  const initCategories = useMemo(
    () => sortCategoriesAndMenuItems(rawCategories, "placeInTheList"),
    [menu]
  );

  const [categories, setCategories] = useState(initCategories);
  const [activeCategory, setActiveCategory] = useState(categories[0]?.id);
  const { isLoading } = useSelector((state) => state[STORE_NAMES.menu]);
  const categoriesRef = useRef(null);
  const menuRef = useRef(null);
  const menuItemSelectedRef = useRef(null);
  const [opacity, setOpacity] = useState(0);
  const [categoriesTopDistance, setCategoriesTopDistance] = useState(20);

  let [searchParams, setSearchParams] = useSearchParams();

  const [selectedMenuItemId, setSelectedMenuItemId] = useState(
    searchParams.get(QUERY_PARAMS.selectedItem) || null
  );
  useEffect(() => {
    setCategories(initCategories);
  }, [initCategories]);

  const handleCategoryOnClick = (item) => {
    const anchorTarget = document.getElementById(item.id);
    const topPosition = anchorTarget.offsetTop - menuRef.current.clientHeight;
    if (isOrderSection) {
      return categoriesRef.current.scrollTo({
        top: topPosition - 140,
        behavior: "smooth",
      });
    }
    scrollElement.scrollTo({
      top: topPosition,
      behavior: "smooth",
    });
  };
  const handleScroll = () => {
    const categoriesTopDistance =
      categoriesRef.current?.getBoundingClientRect().top;
    setOpacity(Math.min(1, Math.max(0, 1 - categoriesTopDistance / 20)));
    setCategoriesTopDistance(
      Math.max(0, categoriesTopDistance - menuRef.current?.clientHeight)
    );
  };

  useEffect(() => {
    if (isOrderSection) {
      return categoriesRef?.current?.addEventListener("scroll", handleScroll);
    }
    scrollElement.addEventListener("scroll", handleScroll);

    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
      if (isOrderSection) {
        return categoriesRef?.current?.removeEventListener(
          "scroll",
          handleScroll
        );
      }
    };
  }, [scrollElement]);

  const isGuest = type === ENUMS.types.GUEST;
  const isAdmin = type === ENUMS.types.ADMIN;

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };
  const { displayDataByLanguage } = useLanguage();

  const filteredCategoryId = initCategories
    .flatMap((category) =>
      filterMenuItemByIsPublishedAndSchedule(
        category.menuItems,
        category.id,
        displayDataByLanguage,
        searchValue
      )
    )
    .flatMap((menuItem) => menuItem.categoryId);

  useEffect(() => {
    const lowerCasedSearchValue = searchValue.trim().toLowerCase();

    const matchesSearch = (category) => {
      return category.name.some((nameObj) => {
        const categoryNameInAnyLanguage = nameObj.value.toLowerCase();
        return advanceSearch({
          string: categoryNameInAnyLanguage,
          searchString: lowerCasedSearchValue,
        });
      });
    };

    const filterMenuItemsBySearch = (menuItems) => {
      return menuItems.filter((menuItem) =>
        menuItem.name.some((nameObj) => {
          const menuItemNameInAnyLanguage = nameObj.value.toLowerCase();
          return advanceSearch({
            string: menuItemNameInAnyLanguage,
            searchString: lowerCasedSearchValue,
          });
        })
      );
    };

    const filterCategories = (categories) => {
      return categories
        .map((category) => {
          const matchedItems = filterMenuItemsBySearch(category.menuItems);

          if (matchesSearch(category)) {
            return {
              ...category,
              menuItems: category.menuItems,
            };
          }

          if (matchedItems.length > 0) {
            return {
              ...category,
              menuItems: matchedItems,
            };
          }

          return null;
        })
        .filter(Boolean);
    };

    if (lowerCasedSearchValue.length > 0) {
      const filteredCategories = filterCategories(initCategories);

      if (JSON.stringify(filteredCategories) !== JSON.stringify(categories)) {
        setCategories(filteredCategories);
      }
    } else {
      if (JSON.stringify(initCategories) !== JSON.stringify(categories)) {
        setCategories(initCategories);
      }
    }
  }, [searchValue, initCategories, filteredCategoryId, categories]);

  if (isLoading) return;

  return (
    <ImageVisibilityProvider>
      <div
        className={cx("AdminOrderMenuDisplayWrapper", {
          isGuest: isGuest,
          isAdmin: isAdmin,
          hideOverflowY: categories?.length < 1,
        })}
      >
        <div
          className="AdminOrderMenuDisplayCategorySelection"
          style={
            isGuest
              ? {
                  top: categoriesTopDistance,
                  opacity: opacity,
                  pointerEvents: opacity > 0 ? "auto" : "none",
                }
              : {}
          }
          ref={menuRef}
        >
          <Category
            activeCategoryId={activeCategory}
            items={categories}
            onClick={handleCategoryOnClick}
            distanceLeft={distanceLeft}
            isMultiLanguage
            isOrderSection={isOrderSection}
          />
          {hasSearchInput && (
            <Search
              onChange={(value) => {
                handleSearchChange(value);
              }}
              value={searchValue}
              type={SEARCH_ENUMS.types.TYPE_B}
            />
          )}
        </div>
        <div className="AdminOrderMenuDisplayCategory" ref={categoriesRef}>
          {menu.categories.length > 0 ? (
            categories?.length > 0 ? (
              categories.map((category, index) => (
                <AdminOrderMenuSection
                  key={index}
                  category={category}
                  menuViewType={menuViewType}
                  setActiveCategory={setActiveCategory}
                  onClick={onClick}
                  onAdd={onAdd}
                  isAdmin={isAdmin}
                  searchValue={searchValue}
                  isOrderSection={isOrderSection}
                  categoriesRef={categoriesRef}
                  scrollElement={scrollElement}
                  categories={categories}
                  setSearchParams={setSearchParams}
                  setSelectedMenuItemId={setSelectedMenuItemId}
                />
              ))
            ) : (
              <EmptyState
                description={t("emptyStates.noSearchResults")}
                icon={SearchIcon}
                isAdmin
                className="AdminMenuAdminOrderMenuDisplayEmptyState"
              />
            )
          ) : (
            <div className="AdminOrderMenuDisplayEmptyState">
              <EmptyState
                description={t("menu.category.emptyGuestMenuTitle")}
                icon={EmptyMenu}
                isAdmin={isAdmin}
              />
            </div>
          )}
        </div>
        {selectedMenuItemId && (
          <MenuItemSelected
            menuItemId={String(selectedMenuItemId)}
            setSelectedMenuItemId={setSelectedMenuItemId}
            setSearchParams={setSearchParams}
            menuItemSelectedRef={menuItemSelectedRef}
          />
        )}
      </div>
    </ImageVisibilityProvider>
  );
};

AdminOrderMenuDisplay.propTypes = {
  /**
   * The id of menu view type to display (e.g., SCROLL, GRID, etc.).
   */
  menuViewType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * The onClick function to be called when a specific action is triggered.
   */
  onClick: PropTypes.func,

  /**
   * The onAdd function to be called when adding an item.
   */
  onAdd: PropTypes.func,

  /**
   * The distance left for padding
   */
  distanceLeft: PropTypes.number,

  /**
   * The types of the component
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),

  /**
   * The most expensive price of menu
   */
  mostExpensivePrice: PropTypes.number,

  hasSearchInput: PropTypes.bool,

  isOrderSection: PropTypes.bool,

  menu: PropTypes.object,

  scrollElement: PropTypes.object,
};

export default AdminOrderMenuDisplay;
