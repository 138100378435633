import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import GoogleIcon from "assets/icons/sosial-media/Google.svg";
import AppleIcon from "assets/icons/sosial-media/Apple.svg";
import FaceBookIcon from "assets/icons/sosial-media/Facebook.svg";
import { toast } from "react-toastify";

import "./OrSignUpWith.scss";

const OrSignUpWith = () => {
  const { t } = useTranslation();

  const socialMediaOptions = [
    {
      icon: AppleIcon,
      label: "Apple Login",
      className: "apple",
      onClick: () => toast.warn(t("toastMessages.warning.socials")),
    },
    {
      icon: GoogleIcon,
      label: "Google Login",
      className: "google",
      onClick: () => toast.warn(t("toastMessages.warning.socials")),
    },
    {
      icon: FaceBookIcon,
      label: "Facebook Login",
      className: "facebook",
      onClick: () => toast.warn(t("toastMessages.warning.socials")),
    },
  ];

  return (
    <div className="GuestAuthFooter">
      <div className="GuestAuthSignUpWith">
        <div className="LeftLine"></div>
        <div className="FooterText">
          <h6 className="Medium">{t("auth.signUpWith")}</h6>
        </div>
        <div className="RightLine"></div>
      </div>
      <div className="GuestAuthSocials">
        <div className="GuestAuthSocials">
          {socialMediaOptions.map(
            ({ icon, label, onClick, className }, index) => (
              <div
                className={cx("GuestAuthSocial", className, {
                  isActive: onClick,
                })}
                key={index}
                onClick={onClick}
              >
                <img src={icon} alt={label} />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default OrSignUpWith;
