import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css/effect-fade";
import "swiper/css";

import {
  updateBasket,
  updateFavorites,
  updateOrderItemByIndex,
} from "redux/slices/basketStore";
import {
  calculateMenuItemPrice,
  findItemById,
  calculateMenuItemPriceBySchedule,
} from "utils/general";
import MenuItemHeader, {
  ENUMS as Header_ENUMS,
} from "components/elements/menu-item-header/MenuItemHeader";
import MenuItemInfo from "components/elements/menu-item-info/MenuItemInfo";
import CTAButton, {
  ENUMS as CTA_ENUMS,
} from "components/buttons/cta-button/CTAButton";
import ModificationsMenu from "components/elements/modifications-menu/ModificationsMenu";
import {
  findGuest,
  getBasketModificationsWithDefaultValues,
  findMenuCategoryByItemId,
  findMenuItemByIdAndPublished,
} from "utils/helpers";
import { ROUTE_NAME, QUERY_PARAMS } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import useLanguage from "utils/hooks/useLanguage";
import { createDOBucketName } from "utils/DO-Spaces";
import AnimatedPlusMinusButton from "components/buttons/animated-plus-minus-button/AnimatedPlusMinusButton";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { useMixpanel } from "utils/context-api/MixpanelContext";
import { MP_EVENTS, MP_PROP_NAMES } from "utils/constants/mixpanel";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";
import If from "components/if/If";

import "./MenuItem.scss";

const MenuItem = () => {
  const { trackMixpanel } = useMixpanel();
  const navigate = useNavigate();
  const { displayDataByLanguage } = useLanguage();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { businessId, menuItemId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const isEditMode = searchParams.get("isEditMode");
  const basketIndex = searchParams.get("basketIndex");

  const userId = useSelector((state) => state[STORE_NAMES.guest].id);
  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const guest = findGuest(userId, basketOrders);

  const favoriteItems = guest?.favoriteItems || [];

  const orderItems = guest?.orderItems || [];

  const menu = useSelector((state) => state[STORE_NAMES.menu].data);
  const { shouldDisplayMenuItemImage } = useMenuHelper();

  const item =
    menu &&
    (basketIndex && orderItems[basketIndex]?.item
      ? findMenuItemByIdAndPublished(
          menu.categories,
          orderItems[basketIndex].item,
          dispatch,
          guest.id
        )
      : findItemById(menuItemId, menu));

  const menuItem = {
    ...item,
    coverImageSrc: item ? createDOBucketName(item.coverImageSrc) : null,
    otherImagesSrc: item
      ? item.otherImagesSrc?.map((otherImageSrc) =>
          createDOBucketName(otherImageSrc)
        )
      : null,
  };

  const allModifications = menu && findItemById(menuItemId, menu).modifications;
  const [count, setCount] = useState(
    orderItems && basketIndex && orderItems[basketIndex]
      ? orderItems[basketIndex].count
      : 1
  );

  const discountPrice = calculateMenuItemPriceBySchedule(menuItem);

  const menuItemPrice = menuItem.priceSell;

  const [selectedModifications, setSelectedModifications] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0);

  const [focusedModificationOption, setFocusedModificationOption] =
    useState(null);

  useEffect(() => {
    if (menu) {
      const defaultModifications = basketIndex
        ? menuItem.modifications
        : getBasketModificationsWithDefaultValues(menuItem.modifications);
      setSelectedModifications(defaultModifications);
    }
  }, [menu]);

  useEffect(() => {
    if (menu) {
      setFinalPrice(
        calculateMenuItemPrice({
          ...menuItem,
          priceSell: menuItemPrice + discountPrice,
          modifications: selectedModifications,
        })
      );
    }
  }, [selectedModifications, menu]);

  const handleOnFavorite = () => {
    const itemName = item.name[0].value;
    dispatch(
      updateFavorites({
        userId,
        menuItemID: menuItem.id,
      })
    );
    trackMixpanel(`${MP_EVENTS.menu.liked}`, {
      [MP_PROP_NAMES.itemId]: menuItemId,
      [MP_PROP_NAMES.itemName]: itemName,
    });
  };

  const handleGoBack = () => {
    navigate(searchParams.get(QUERY_PARAMS.from));
  };

  const handleOnMinus = () => {
    count > 1 && setCount((prev) => --prev);
  };

  const handleOnPlus = () => {
    setCount((prev) => ++prev);
  };

  const handleAddToBasket = () => {
    const filteredModifications = selectedModifications.filter(
      (modification) => {
        return modification.options.length !== 0;
      }
    );
    dispatch(
      updateBasket({
        menuItem: {
          id: menuItem.id,
          modifications: filteredModifications.map((modification) => {
            return {
              id: modification.id,
              options: modification.options.map((option) => {
                return {
                  id: option.id,
                  count: option.count ?? 1,
                };
              }),
            };
          }),
        },
        count: count,
        userId,
      })
    );
    navigate(searchParams.get(QUERY_PARAMS.from));
  };

  const handleSaveChanges = () => {
    dispatch(
      updateOrderItemByIndex({
        menuItem: {
          ...menuItem,
          modifications: selectedModifications,
        },
        count: count,
        userId,
        basketIndex: parseInt(basketIndex),
      })
    );
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.basket}`
    );
  };
  const menuItemName = menuItem?.name
    ? displayDataByLanguage(menuItem?.name)
    : "";

  const category = findMenuCategoryByItemId(menu.categories, menuItem?.id);

  useEffect(() => {
    if (
      !menuItem ||
      !menuItem.isPublished ||
      menuItem.isArchived ||
      !category.isPublished
    ) {
      const redirectPath = searchParams.get(QUERY_PARAMS.from);
      setSearchParams((prev) => prev.delete(QUERY_PARAMS.selectedItem));
      navigate(redirectPath);
    }
  }, [menuItem, category, businessId, navigate, basketIndex, searchParams]);

  if (
    !menuItem ||
    !menuItem.isPublished ||
    menuItem.isArchived ||
    !category?.isPublished
  ) {
    return null;
  }

  const shouldShowSlider =
    menuItem.otherImagesSrc?.length > 1 && shouldDisplayMenuItemImage;

  return (
    <div className="MenuItem">
      <MenuItemHeader
        onFavorite={handleOnFavorite}
        itemId={menuItem?.id}
        onGoBack={handleGoBack}
        favoriteItems={favoriteItems}
        type={Header_ENUMS.types.TYPE_C}
      />

      <If state={shouldShowSlider}>
        <Swiper
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          loop={true}
          effect={"fade"}
          modules={[EffectFade, Autoplay]}
          className="MenuItemSlider"
        >
          {menuItem.otherImagesSrc.map((imageSrc, index) => (
            <SwiperSlide className="SwiperSlide" key={index}>
              <img
                className="MenuItemCarouselImage"
                src={imageSrc}
                alt={menuItemName}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </If>
      <If state={!shouldShowSlider && shouldDisplayMenuItemImage}>
        <ImageWithPlaceholder
          imageSource={menuItem.coverImageSrc}
          placeholder={IMAGE_ITEM_PLACEHOLDER}
          alt={String(menuItem.id)}
          className="MenuItemCoverImage"
        />
      </If>
      <div
        className={cx("MenuItemInfoContainer", {
          WithPhoto: shouldDisplayMenuItemImage,
          WithoutPhoto: !shouldDisplayMenuItemImage,
        })}
      >
        <h2 className="SemiBold">{menuItemName}</h2>
        <MenuItemInfo
          onMinus={handleOnMinus}
          onPlus={handleOnPlus}
          menuItem={menuItem}
          count={count}
          discountPrice={discountPrice}
          menuItemPrice={menuItemPrice}
          disableMinusButtonAtOne
        />
      </div>
      {allModifications.length > 0 && (
        <div className="MenuItemModifications">
          {allModifications.map((modification) => (
            <ModificationsMenu
              key={modification.id}
              data={modification}
              selectedOptions={selectedModifications?.find(
                (options) => options.id === modification.id
              )}
              setSelectedModifications={setSelectedModifications}
              focusedModificationOption={focusedModificationOption}
              setFocusedModificationOption={setFocusedModificationOption}
            />
          ))}
        </div>
      )}
      <div className="MenuItemFooter">
        <AnimatedPlusMinusButton
          onMinusClick={handleOnMinus}
          onPlusClick={handleOnPlus}
          hasBorder
          count={count}
          doAnimate
          disableMinusButtonAtOne
        />
        {isEditMode ? (
          <CTAButton
            className="SaveChanges"
            onClick={handleSaveChanges}
            name={t("buttons.saveChanges")}
            type={CTA_ENUMS.types.TYPE_N}
            price={count * finalPrice}
          />
        ) : (
          <CTAButton
            className="AddToBasketBtn"
            onClick={handleAddToBasket}
            name={t("buttons.addBasket")}
            type={CTA_ENUMS.types.TYPE_N}
            price={count * finalPrice}
          />
        )}
      </div>
    </div>
  );
};

export default MenuItem;
