import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Range from "components/forms/range/Range";
import CTAButton, {
  ENUMS as ENUMS_CTA_BUTTON,
} from "components/buttons/cta-button/CTAButton";
import CloseButton from "components/buttons/close-button/CloseButton";
import FilterGroup from "./filter-group/FilterGroup";
import SortChipItem from "./sort-chip-item/SortChipItem";
import AccordionContainer from "components/elements/accordion-container/AccordionContainer";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ENUMS } from "components/buttons/close-button/CloseButton";
import { STORE_NAMES } from "utils/constants/redux";
import IosSwitch from "components/forms/ios-switch/IosSwitch";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { setFilterOptions, setFilterMaxPrice } from "redux/slices/guestStore";
import GuestModal, {
  COMPONENT_SIZES,
  MODAL_POSITIONS,
  POSITION_TYPES,
} from "components/guest/guest-modal/GuestModal";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";
import usePrevious from "utils/hooks/usePrevious";

import styles from "./MenuFilterModal.module.scss";

export const SORT_OPTIONS = {
  az: {
    key: "az",
    name: "az",
  },
  cheapToExpensive: {
    key: "cheapToExpensive",
    name: "cheapToExpensive",
  },
  expensiveToCheap: {
    key: "expensiveToCheap",
    name: "expensiveToCheap",
  },
};

const MenuFilterModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { businessId } = useParams();
  const { categories, existTags, highestMenuItemPrice } = useMenuHelper();

  const previousHighestMenuItemPrice = usePrevious({
    value: highestMenuItemPrice,
    dep: [highestMenuItemPrice],
  });

  const initialFilter = useMemo(() => {
    return {
      selectedCategories: [],
      selectedTags: [],
      priceRange: {
        min: 0,
        max: highestMenuItemPrice,
      },
      showDiscountedItems: null,
      sortBy: null,
    };
  }, [highestMenuItemPrice]);

  const [filter, setFilter] = useState(initialFilter);

  const menuFilters = useSelector(
    (state) => state[STORE_NAMES.guest].menuFilters
  );

  const showFilterPopup = Boolean(searchParams.get(QUERY_PARAMS.showPopup));

  useEffect(() => {
    if (
      (menuFilters.priceRange.max === 0 ||
        menuFilters.priceRange.max === previousHighestMenuItemPrice) &&
      highestMenuItemPrice !== 0
    ) {
      dispatch(setFilterMaxPrice(highestMenuItemPrice));
    }
  }, [
    highestMenuItemPrice,
    menuFilters.priceRange.max,
    previousHighestMenuItemPrice,
  ]);

  useEffect(() => {
    if (!showFilterPopup) return;
    setFilter(menuFilters);
  }, [showFilterPopup, menuFilters]);

  const handlePriceRangeChange = (values) => {
    setFilter((prevFilter) => {
      return {
        ...prevFilter,
        priceRange: values,
      };
    });
  };

  const handleApplyClick = async () => {
    dispatch(setFilterOptions(filter));
    searchParams.delete(QUERY_PARAMS.showPopup);
    setSearchParams(searchParams);
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.filterResult}`,
      {
        replace: true,
      }
    );
  };

  const handleOnResetClick = () => {
    dispatch(setFilterOptions(initialFilter));
  };

  const selectSortItemHandler = (chip) =>
    setFilter((prevFilter) => ({
      ...prevFilter,
      sortBy: chip.key === filter.sortBy ? null : chip.key,
    }));

  const toggleDiscountedItemHandler = () =>
    setFilter((prev) => {
      return {
        ...prev,
        showDiscountedItems: !prev.showDiscountedItems,
      };
    });

  const RangeComponent = (
    <Range
      onChange={(values) => handlePriceRangeChange(values)}
      max={highestMenuItemPrice}
      min={0}
      step={1}
      currentValue={filter.priceRange}
    />
  );

  const modalHeader = (
    <div className={styles.Header}>
      <h4 className={`${styles.Title} SemiBold`}>{t("menu.filter.filters")}</h4>
      <CloseButton
        onClick={() => {
          searchParams.delete(QUERY_PARAMS.showPopup);
          setSearchParams(searchParams);
        }}
        className={styles.HeaderCloseButton}
        type={ENUMS.types.TYPE_S}
      />
    </div>
  );

  const modalBody = (
    <div className={styles.Body}>
      <div className={styles.BodySortContainer}>
        <h2 className="SemiBold">{t("common.sort")}</h2>
        <div className={styles.BodySortWrapper}>
          {Object.values(SORT_OPTIONS).map((chip) => (
            <SortChipItem
              key={chip.key}
              name={chip.name}
              isSelected={chip.key === filter.sortBy}
              onClick={() => selectSortItemHandler(chip)}
            />
          ))}
        </div>
      </div>
      <AccordionContainer
        name={t("inputs.price")}
        bodyComponent={RangeComponent}
      />
      {categories.length > 0 && (
        <FilterGroup
          groupName={t("menu.category.categories")}
          data={categories}
          name={"selectedCategories"}
          setFilter={setFilter}
          selectedData={filter.selectedCategories}
        />
      )}
      {existTags.length > 0 && (
        <FilterGroup
          groupName={t("menu.tag.tags")}
          data={existTags}
          name={"selectedTags"}
          setFilter={setFilter}
          selectedData={filter.selectedTags}
        />
      )}
      <div className={styles.BodyActionsContainer}>
        <div className={styles.BodyActions}>
          <h5 className="SemiBold">{t("buttons.discountProducts")}</h5>
          <IosSwitch
            isChecked={filter.showDiscountedItems}
            onChange={toggleDiscountedItemHandler}
          />
        </div>
      </div>
    </div>
  );

  const modalFooter = (
    <div className={styles.Footer}>
      <CTAButton
        onClick={handleOnResetClick}
        name={t("buttons.clear")}
        type={ENUMS_CTA_BUTTON.types.TYPE_L}
        className={styles.ClearBtn}
      />
      <CTAButton
        onClick={handleApplyClick}
        name={t("modal.apply")}
        type={ENUMS_CTA_BUTTON.types.TYPE_F}
        className={styles.ApplyBtn}
      />
    </div>
  );

  return (
    <div className={styles.Modal}>
      <GuestModal
        className={styles.Background}
        containerClassname={styles.Container}
        modalClassname={styles.ModalClassName}
        header={modalHeader}
        body={modalBody}
        footer={modalFooter}
        openSlide={showFilterPopup}
        slideDirection={POSITION_TYPES.DOWN}
        modalPosition={MODAL_POSITIONS.BOTTOM}
        componentMaxHeight={COMPONENT_SIZES.FULL_HEIGHT}
      />
    </div>
  );
};

export default MenuFilterModal;
