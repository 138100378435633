import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import SmallButton, {
  ENUMS as SMALL_BUTTON_ENUMS,
} from "components/buttons/small-button/SmallButton";
import Modal from "components/modal/Modal";
import Range from "components/forms/range/Range";
import AccordionContainer from "components/elements/accordion-container/AccordionContainer";
import CTAButton, {
  ENUMS as CTA_BUTTON_ENUMS,
} from "components/buttons/cta-button/CTAButton";
import { resetMenuFilters, setFilterOptions } from "redux/slices/guestStore";
import { STORE_NAMES } from "utils/constants/redux";
import { findHighestDefaultMenuItemPrice } from "utils/helpers";
import TagWithIcon, {
  ENUMS as TAG_ENUMS_WITH_ICON,
} from "components/elements/tag-with-icon/TagWithIcon";
import useTagsWIcon from "utils/hooks/useTagsWithIcon";
import useLanguage from "utils/hooks/useLanguage";

import "./MenuFilterModal.scss";

const MenuFilterModal = ({
  openSlide,
  mainElementRef,
  onClose,
  setFiltersModified,
}) => {
  const { t } = useTranslation();
  const { displayDataByLanguage } = useLanguage();
  const dispatch = useDispatch();
  const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);
  const menuFilters = useSelector(
    (state) => state[STORE_NAMES.guest].menuFilters
  );

  const [maxPrice, setMaxPrice] = useState(findHighestDefaultMenuItemPrice(menu));
  const [priceRange, setPriceRange] = useState([
    menuFilters.priceRange.min,
    menuFilters.priceRange.max,
  ]);

  const [selectedTags, setSelectedTags] = useState(
    menuFilters?.selectedTags || []
  );
  const allTagsWithIcon = menu?.categories
    .flatMap((category) => category?.menuItems)
    .flatMap(
      (menuItem) =>
        useTagsWIcon(menuItem, displayDataByLanguage, true).allTagsWithIcon
    );

  const menuItemsTags = allTagsWithIcon.reduce((acc, tag) => {
    if (!acc.some((item) => item.id === tag.id)) {
      acc.push(tag);
    }
    return acc;
  }, []);

  useEffect(() => {
    setMaxPrice(findHighestDefaultMenuItemPrice(menu));
  }, [menu]);

  useEffect(() => {
    if (
      (!menuFilters.priceRange.max || menuFilters.priceRange.max === 0) &&
      maxPrice !== 0
    ) {
      dispatch(
        setFilterOptions({
          priceRange: {
            min: 0,
            max: maxPrice,
          },
          selectedTags,
        })
      );
    }
  }, [maxPrice, menuFilters.priceRange]);

  useEffect(() => {
    setPriceRange([menuFilters.priceRange.min, menuFilters.priceRange.max]);
    setSelectedTags(menuFilters.selectedTags || []);
  }, [openSlide]);

  useEffect(() => {
    if (
      menuFilters.priceRange.min === 0 &&
      menuFilters.priceRange.max === maxPrice &&
      menuFilters?.selectedTags?.length === 0
    ) {
      setFiltersModified(false);
    }
  }, [menuFilters, selectedTags]);

  const handlePriceRangeChange = (values) => {
    const [min, max] = values;
    if (min === max) {
      return;
    }
    setPriceRange(values);
  };

  const handleApplyClick = async () => {
    dispatch(
      setFilterOptions({
        priceRange: {
          min: priceRange[0],
          max: priceRange[1],
        },
        selectedTags,
      })
    );
    if (
      priceRange[0] !== 0 ||
      priceRange[1] !== maxPrice ||
      selectedTags.length !== 0
    ) {
      setFiltersModified(true);
    } else {
      setFiltersModified(false);
    }
    onClose();
  };

  const handleOnResetClick = () => {
    setPriceRange([0, maxPrice]);
    setSelectedTags([]);
    dispatch(resetMenuFilters());
    dispatch(
      setFilterOptions({
        priceRange: { min: 0, max: maxPrice },
      })
    );
    setFiltersModified(false);
    onClose();
  };

  const MenuFilterModalHeader = (
    <div className="MenuFilterModalHeader">
      <h4 className="SemiBold">{t("menu.filter.filters")}</h4>
      <SmallButton
        name={t("buttons.reset")}
        onClick={handleOnResetClick}
        type={SMALL_BUTTON_ENUMS.types.TYPE_SEMI_BOLD}
      />
    </div>
  );

  const RangeComponent = (
    <Range
      onChange={(values) => handlePriceRangeChange(values)}
      max={maxPrice}
      step={1}
      currentValue={priceRange || null}
    />
  );
  const chunkArray = (array, size) => {
    return array.reduce((acc, _, i) => {
      if (i % size === 0) acc.push(array.slice(i, i + size));
      return acc;
    }, []);
  };
  const MenuFilterModalBody = (
    <div className="MenuFilterModalBody">
      {menuItemsTags?.length > 0 && (
        <div className="MenuFilterModalTags">
          <h6 className="SemiBold">{t("menu.tag.tags")}</h6>
          <div className="MenuFilterModalTagsScroll">
            {chunkArray(menuItemsTags, 6).map((chunk, index) => (
              <TagWithIcon
                key={index}
                type={TAG_ENUMS_WITH_ICON.types.TYPE_B}
                items={chunk}
                isActiveOnly={true}
                activeTags={selectedTags}
                setActiveTags={setSelectedTags}
              />
            ))}
          </div>
        </div>
      )}
      <AccordionContainer
        name={t("menu.filter.priceRange")}
        bodyComponent={RangeComponent}
      />
      <div className={cx("MenuFilterModalApplyButton")}>
        <CTAButton
          onClick={handleApplyClick}
          name={t("buttons.apply")}
          type={CTA_BUTTON_ENUMS.types.TYPE_E}
        />
      </div>
    </div>
  );

  return (
    <div className="MenuFilterModal">
      <Modal
        header={MenuFilterModalHeader}
        body={MenuFilterModalBody}
        mainElementRef={mainElementRef}
        openSlide={openSlide}
      />
    </div>
  );
};

MenuFilterModal.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mainElementRef: PropTypes.object,
  setFiltersModified: PropTypes.func.isRequired,
};
export default MenuFilterModal;
