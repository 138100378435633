import React from "react";
import { useTranslation } from "react-i18next";

import TopBar from "pages/client/menu-v2/top-bar/TopBar";
import Category from "pages/client/menu-v2/category/Category";
import MenuCategoryChips from "pages/client/menu-v2/menu-categories/MenuCategoryChips";
import EmptyState from "components/admin/empty-state/EmptyState";
import If from "components/if/If";
import useManageChipsWhenScroll from "utils/hooks/useManageChipsWhenScroll";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";
import ScrollTop from "components/buttons/scroll-top/ScrollTop";
import { useGuestLayout } from "pages/client/menu-v2/GuestLayoutContext";
import ICON_EMPTY_MENU from "assets/icons/menu/guest-empty-menu.svg";

import styles from "./Menu.module.scss";

const Menu = () => {
  const { t } = useTranslation();
  const { categories } = useMenuHelper();
  const { isHamburgerVisible } = useGuestLayout();
  const { handleChipClick, activeCategoryId, categoryRefs } =
    useManageChipsWhenScroll({
      categories,
    });

  const shouldShowEmptyState = categories.length === 0;

  return (
    <div className={styles.Menu}>
      <TopBar />
      <div className={styles.Container}>
        <If state={shouldShowEmptyState}>
          <div className="MenuDisplayNoResult">
            <EmptyState
              title={t("menu.category.emptyGuestMenuTitle")}
              description={t("menu.category.emptyGuestMenuDescription")}
              icon={ICON_EMPTY_MENU}
              isAdmin={false}
            />
          </div>
        </If>
        <If state={!shouldShowEmptyState}>
          <MenuCategoryChips
            categories={categories}
            activeCategoryId={activeCategoryId}
            onChipClick={handleChipClick}
          />
          <div className={styles.Wrapper}>
            {categories.map((category) => (
              <div
                key={category.id}
                ref={(el) => (categoryRefs.current[category.id] = el)}
              >
                <Category category={category} />
              </div>
            ))}
          </div>
        </If>
      </div>
      <If state={!isHamburgerVisible}>
        <ScrollTop />
      </If>
    </div>
  );
};

export default Menu;
