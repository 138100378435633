import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import TypingEffect from "components/typing-effect/TypingEffect";
import BestChoiceModal from "pages/client/magic-order/best-choice-modal/BestChoiceModal";
import { formatResult } from "pages/client/magic-order/helper";
import Loya from "assets/icons/menuv2/Loya.png";
import { ReactComponent as FlashIcon } from "assets/icons/menuv2/Flash.svg";
import { STORE_NAMES } from "utils/constants/redux";

import "./MagicOrderBody.scss";

const MagicOrderBody = ({
  resultsHistory,
  loadingAiSuperSearchMenu,
  handleSaveSpeech,
  speechToTextBodyRef,
  lastPromptRef,
  lastResultRef,
}) => {
  const { t } = useTranslation();
  const menu = useSelector((state) => state[STORE_NAMES.menu].data);

  const texts = [
    t("recommend.fitMyBudget"),
    t("recommend.tastiestDishes"),
    t("recommend.delicious"),
    t("recommend.smtQuick"),
  ];

  return (
    <>
      {resultsHistory.length === 0 ? (
        <div className="SpeechToTextContainerContentEmptyState">
          <img alt={"Loya"} className="EmptyStateIcon" src={Loya} />
          <div className="EmptyStateTextContainer">
            <p className={"SemiBold EmptyStateTitle"}>
              {t("record.introductionLoya")}
            </p>
            <h5 className={"Regular EmptyStateDescription"}>
              {t("record.askMeAnything")}
            </h5>
          </div>
        </div>
      ) : (
        <div className="SpeechToTextContainerContent" ref={speechToTextBodyRef}>
          {resultsHistory.map((result, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  className="SpeechToTextContainerMessage"
                  ref={
                    index === resultsHistory.length - 1 ? lastPromptRef : null
                  }
                >
                  <h5 className="Medium">{result.prompt}</h5>
                </div>

                <div
                  className={cx("SpeechToTextContainerResponseMessage", {
                    isLoading: !result?.response,
                  })}
                  ref={
                    index === resultsHistory.length - 1 ? lastResultRef : null
                  }
                >
                  {!result?.response &&
                    (loadingAiSuperSearchMenu ? (
                      <div className={"LoaderContainer"}>
                        <img src={Loya} alt={"Loading"} />
                        <p className="h7 Regular LoaderText">
                          {t("record.loyaIsTyping")}
                          <TypingEffect
                            typingSpeed={100}
                            deletingSpeed={100}
                            pauseDuration={200}
                            texts={["..."]}
                            textContainer={(displayText) => {
                              return (
                                <p className="TypingText">{displayText}</p>
                              );
                            }}
                          />
                        </p>
                      </div>
                    ) : (
                      <div className="NoResultContainer">
                        <img src={Loya} alt="noResult" />
                        <h6 className="Medium">{t("buttons.tryAgain")}</h6>
                      </div>
                    ))}

                  {result?.response?.success ? (
                    <BestChoiceModal
                      results={formatResult(result?.response?.result, menu)}
                    />
                  ) : (
                    result?.response?.result && (
                      <div className="NoResultContainer">
                        <img src={Loya} alt="noResult" />
                        <h6 className="Medium">{result?.response?.result}</h6>
                      </div>
                    )
                  )}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      )}
      {resultsHistory.length === 0 && (
        <div className="Recommends">
          {texts.map((text, index) => {
            return (
              <div
                key={index}
                className="Recommend"
                onClick={() => handleSaveSpeech({ prompt: text })}
              >
                <div className="FlashIconWrapper">
                  <FlashIcon />
                </div>
                <h6>{text}</h6>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

MagicOrderBody.propTypes = {
  resultsHistory: PropTypes.array,
  loadingAiSuperSearchMenu: PropTypes.bool,
  handleSaveSpeech: PropTypes.func,
  speechToTextBodyRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  lastPromptRef: PropTypes.object,
  lastResultRef: PropTypes.object,
};

export default MagicOrderBody;
