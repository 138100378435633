import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import cx from "classnames";

import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import GuestModal, {
  MODAL_POSITIONS,
  POSITION_TYPES,
} from "../guest/guest-modal/GuestModal";
import { ROUTE_NAME } from "utils/constants/routes";
import { MP_EVENTS } from "utils/constants/mixpanel";
import { useMixpanel } from "utils/context-api/MixpanelContext";
import OrSignUpWith from "pages/client/auth/sign-up/or-sign-up-with/OrSignUpWith";
import AnimatedWord from "components/guest-account-info-modal/animated-word/AnimatedWord";
import { useGuestLayout } from "pages/client/menu-v2/GuestLayoutContext";

import "./GuestAuthModal.scss";

const GuestAuthModal = ({ openSlide, onCancel, setOpenSlide }) => {
  const navigate = useNavigate();
  const { trackMixpanel } = useMixpanel();
  const { t } = useTranslation();
  const { businessId } = useParams();
  const { closeHamburger, isHamburgerVisible } = useGuestLayout();

  const messages = [
    t("auth.message.first"),
    t("auth.message.second"),
    t("auth.message.third"),
    t("auth.message.fourth"),
    t("auth.message.first"),
  ];
  const handleSignUp = () => {
    setOpenSlide(false);
    businessId
      ? navigate(
          `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.auth}${ROUTE_NAME.signUp}`
        )
      : navigate(`${ROUTE_NAME.client}${ROUTE_NAME.auth}${ROUTE_NAME.signUp}`);
    trackMixpanel(`${MP_EVENTS.auth.clickSignup}`);
  };
  const handleSignIn = () => {
    closeHamburger();
    setOpenSlide(false);
    businessId
      ? navigate(
          `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.auth}${ROUTE_NAME.signIn}`
        )
      : navigate(`${ROUTE_NAME.client}${ROUTE_NAME.auth}${ROUTE_NAME.signIn}`);
    trackMixpanel(`${MP_EVENTS.auth.clickSignIn}`);
  };

  const GuestAuthModalHeader = (
    <div className="GuestAuthModalHeader">
      <h4 className="GuestAuthModalHeaderTitle SemiBold">
        {t("auth.register")}
      </h4>
      <div className="GuestAuthModalCloseButtonWrapper">
        <CloseButton
          onClick={onCancel}
          type={ENUMS_CLOSE_BUTTON.types.TYPE_S}
        />
      </div>
    </div>
  );

  const GuestAuthModalBody = (
    <div className="GuestAuthModalBody">
      <AnimatedWord messages={messages} firstText={t("auth.signUp")} />
    </div>
  );

  const GuestAuthModalFooter = (
    <div className="GuestAuthModalFooter">
      <PrimaryButton
        onClick={handleSignUp}
        type={PRIMARY_BUTTON_ENUMS.types.TYPE_Z}
        text={t("login.signUp.title")}
      />
      <OrSignUpWith />
      <div className="GuestAuthModalFooterSignIn" onClick={handleSignIn}>
        <h6 className="Medium GuestAuthModalFooterTitle">
          {t("login.alreadyHaveAnAccount")}
        </h6>
        <h5 className="SemiBold GuestAuthModalFooterLogin">
          {t("login.signIn.title")}
        </h5>
      </div>
    </div>
  );

  return (
    <div className="GuestAuthModal">
      <GuestModal
        className={cx("InfoModalBackground", { isSlided: isHamburgerVisible })}
        containerClassname={"ContainerClassname"}
        modalClassname={"ModalClassname"}
        header={GuestAuthModalHeader}
        body={GuestAuthModalBody}
        footer={GuestAuthModalFooter}
        openSlide={openSlide}
        slideDirection={POSITION_TYPES.DOWN}
        modalPosition={MODAL_POSITIONS.BOTTOM}
      />
    </div>
  );
};

GuestAuthModal.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  setOpenSlide: PropTypes.func.isRequired,

  /**
   * Function called when the cancel action is performed
   */ onCancel: PropTypes.func.isRequired,
};

export default GuestAuthModal;
